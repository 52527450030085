@import '../../assets/styles/settings/_index';

.panel {
	// position: relative;
	color: $brand-black;
	font-size: $font--small;
	margin-top: $margin--large * 2;
	@media(max-height: 850px) {
		margin-top: $margin--large;
	}
	&.white {
		color: $brand-white;
	}
}

.panel__para {
	&.gap {
		.panel__paraHeader {
			margin-bottom: $margin * 1.5;
		}
	}
	&.noMargin {
		.panel__paraText {
			margin: 0;
		}
	}
	&.smallMargin {
		.panel__paraText {
			margin-bottom: $margin;
		}
	}
	&.largeMargin {
		.panel__paraHeader {
			margin-bottom: $margin--large;
		}
	}
}

.panel__paraHeader {
	margin: 0;
	font-weight: normal;
	font-size: $font--medium;
}

.panel__paraText {
	font-weight: normal;
	line-height: $line-height;
	margin-bottom: $margin--large;
}

.panel__list {
	margin: 0;
	padding-left: $padding;
	list-style: none;
	&.wheelList {
		padding-left: $padding--large;
		padding-top: $padding--large;
	}
	margin-bottom: $margin--large;
}

.panel__listHeader {
	font-size: $font--smallHeader;
	font-weight: normal;
}

.panel__listItem {
	font-weight: normal;
	margin-bottom: $margin--small;
	position: relative;
	&::before {
		position: absolute;
    left: -10px;
    top: 0;
    content: '-';
	}
	&.wheelList {
		text-indent: -5px;
	}
}

.panel__intro {
	margin-bottom: $margin--large;
}

.panel__introTitle {
	font-weight: normal;
	margin: 0;
	font-size: $font--medium;
}

.panel__introSubtitle {
	font-size: $font--smallHeader;
	font-weight: normal;
	margin-bottom: $margin;
}

.panel__introBody {
	font-size: $font--small;
	font-weight: normal;
}

