@import '../../assets/styles/settings/_index';

.textDisplay {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  margin-top: 4%;
}

.textDisplay__container {
  height: auto;
  width: auto;
  margin: 0 $margin--large;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.textDisplay {
  &.has-2 {
    margin-top: 2.5%;
    @media(max-height: 700px) {
      margin-top: 0;
      .textDisplay__textArea {
        padding-bottom: $padding--large;
      }
    }
    .textDisplay__container {
      width: 47%;
    }
  }
  &.has-3 {
    .textDisplay__container {
      width: 30%;
    }
  }
}

.textDisplay__image {
  width: 100%;
  min-width: 50%;
  height: auto;
  max-height: 275px;
  object-fit: cover;
}

.textDisplay__textArea {
  padding: $padding--large * 2;
  background: $brand-white;
}

.textDisplay__header {
  font-weight: normal;
  margin-bottom: $margin;
  font-size: $font--smallHeader;
  @media (min-height: 850px) {
    font-size: $font--medium;
    margin-bottom: $margin--large;
  }
}

.textDisplay__text {
  max-width: 470px;
  font-size: $font--smallest;
  font-weight: normal;
  margin-bottom: $margin;
  @media (min-height: 850px) {
    font-size: $font--small;
  }
}

.textDisplay__list {
  margin: 0;
  margin-bottom: $margin;
  list-style: square inside url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
  padding: 0 $padding;
  font-size: $font--smallest;
  @media (min-height: 850px) {
    font-size: $font--small;
  }
}

.textDisplay__listHeader {
  font-size: $font--small;
  margin-left: -$margin;
  @media (min-height: 850px) {
    font-size: $font--smallHeader;
  }
  margin-bottom: $margin--small;
  font-weight: normal;
}

.textDisplay__listItem {
  text-indent: -10px;
  font-weight: normal;
}