@import "../../assets/styles/settings/_index";

.verticalNav {
  background: $brand-white;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  width: $nav-width;
  box-shadow: 0.5px 0px 50px rgba(0, 0, 0, 0.07);
  user-select: none;
//   transition: all $timing linear;
  &.border {
    border-right: 1px solid $brand-grey--light;
  }
}

.verticalNav__gridIntegration {
  white-space: nowrap;
  font-size: $font--small;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  font-weight: normal;
  margin-left: $margin--small;
}

.verticalNav__list {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.verticalNav__item {
  & img {
    filter: brightness(0);
  }
  cursor: pointer;
  flex-wrap: wrap;
  position: relative;
  height: 110px;
  overflow: hidden;
  width: 100%;
  //flex-grow: 1;
  //max-height: 100px;
  align-self: flex-start;
  display: flex;

  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  //align-items: left;
  color: $brand-color;
  font-size: $font--smallest;
  // transition: background $timing--quickest ease-in-out;
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
  }
  &.isActive,
  &:hover {
    & img {
      filter: brightness(1);
    }
    background: $brand-color--alt;
    color: $brand-white;
    .verticalNav__icon {
      &.white {
        display: block;
      }
      &.black {
        display: none;
      }
    }
  }
  &:not(:last-of-type) {
    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      /* left: 10%; */
      height: 2px;
      width: 100%;
      z-index: -1;
      background: $brand-grey--light;
    }
  }
  &:last-of-type {
    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      /* left: 10%; */
      height: 2px;
      width: 100%;
      z-index: -1;
      background: $brand-grey--light;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      /* left: 10%; */
      height: 2px;
      width: 100%;
      z-index: -1;
      background: $brand-grey--light;
    }
  }
}

.verticalNav__exit {
  position: absolute;
  bottom: 0;
  //align-self: flex-end;
}

.verticalNav__link {
  margin-top: $margin--small;
  width: $nav-width;
  padding: 0 3px;
}

.verticalNav__icon {
  height: $nav-width / 3.5;
  width: $nav-width;
  &.white {
    display: none;
  }
}

.verticalNav__buttonArea {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 2px solid $brand-grey--light;
}
