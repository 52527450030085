@import "../../assets/styles/settings/_index";
@import "../../assets/styles/tools/_index";

.portfolio {
  height: 100%;
  width: 100%;
  padding: $nav-width;
  padding-top: $nav-width;
  animation: fadeIn $timing linear;
  @media (max-height: 768px) {
    padding: 30px;
    padding-top: 60px;
    padding-left: 50px;
  }

  .header__subtitle {
    max-width: 700px;
  }
  &.hide {
    display: none;
  }
}

.portfolio__navArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: $margin--large * 2;
  margin-top: $margin--large * 3;
  height: calc(100% - (#{$padding--large} * 4) - 80px);

  @media (min-height: 768px) {
    margin-top: 30px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
