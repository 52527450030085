// Global style settings

// Colors
$brand-color: #000;
$brand-color--alt: #ED1D35;
$brand-rgba: rgba(0, 0, 0, .75);
$brand-white: #fff;
$brand-grey: #acacac;
$brand-grey--light: #eee;
$brand-black: #000;

// Dimensions
$margin: 10px;
$margin--small: $margin / 2;
$margin--large: $margin * 2;
$margin--xlarge: $margin * 2;
$padding: 10px;
$padding--small: $padding / 2;
$padding--large: $padding * 2;

// Animations
$timing: 1000ms;
$timing--long: 2000ms;
$timing--quickest: 250ms;
$timing--quick: 500ms;

// Font
$font-path: '';
$font-sans: 'Arial, Helvetica, sans-serif';
$font--largest: 44px;
$font--large: 32px;
$font--medium: 20px;
$font--small: 14px;
$font--smallest: 12px;
$font--smallHeader: 16px;

// Project specific & aliases
$link-color: $brand-white;
$header-height: 50px;
$nav-width: 80px;
$box-shadow: 1px 0 25px rgba(0,0,0,.25);
$card-shadow: 0 10px 28px rgba(0,0,0,0.1), 0 6px 10px rgba(0,0,0,0.08);
$line-height: 20px;
