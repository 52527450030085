@import '../../assets/styles/settings/_index';

.contentLinkArea {
  position: absolute;
  bottom: $nav-width  + $padding--large;
  width: calc(100% - #{$nav-width} - #{$padding--large} * 3);
}

.contentLinkArea__link {
  position: relative;
  padding: $padding 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid $brand-grey--light;
  &:last-of-type {
    border-bottom: 1px solid $brand-grey--light;
  }
}

.contentLinkArea__text {
  font-weight: normal;
  margin-left: $margin--large;
}

.contentLinkArea__image {
  width: 30px;
  height: 30px;
}

.contentLinkArea__arrow {
  position: absolute;
  right: 0;
  width: 40px;
  height: 15px;
}