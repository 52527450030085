@import '../../assets/styles/settings/_index';

$icon-size: 44px;

.CalloutMulti {
  font-weight: normal;
}

.CalloutMulti__main {
  padding-bottom: $padding--large;
  &.horizontal {
    border-top: 2px solid $brand-grey--light;
    display: flex;
    justify-content: space-between;
    padding-top: $padding--large;
  }
  &.noPadding {
    .CalloutMulti__callout{
      padding: 0;
    }
  }
}

.CalloutMulti__title {
  font-weight: normal;
}

.callOutRow__break {
  color: #6e6e6e;
  letter-spacing: 1px;
  font-weight: normal;
  width: 100%;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 8px;
}

.CalloutMultiRow {
  display: flex;
  flex-direction: row;
}
.CalloutMulti__callout {
  width: 49%;
  flex: 1;
  padding: $padding;
  max-width: 220px;
  display: inline-flex;
  flex-wrap: nowrap;
  padding-left: 55px;
  background-repeat: no-repeat;
  background-position: 0px 10px;
  background-size: 44px 44px;
  @media(max-height: 880px) and (max-width: 1650px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  h3 {
    font-size: 24px;
    margin-top: 0;
    font-weight: normal;
    @media(max-height: 880px) and (max-width: 1650px) {
      font-size: $font--small;
    }
  }
}

.CalloutMulti__icon {
  width: $icon-size;
  height: $icon-size;
  margin-right: $margin--large;
  margin-bottom: $margin--large;
  align-self: flex-start;
}

.CalloutMulti__boldText {
  font-weight: normal;
  font-size: $font--smallHeader;
  margin-bottom: $margin--small;

}

.CalloutMulti__text {
  font-size: $font--small;
  margin: 0;
}

.CalloutMulti__textArea {
  margin-bottom: $margin--large;
  @media(max-height: 880px) and (max-width: 1450px) {
    margin-bottom: 0;
  }
}

.CalloutMulti__list {
  padding-left: $padding--large;
  list-style: none;
}

.CalloutMulti__listItem {
  position: relative;
  &::before {
    position: absolute;
    left: -$padding--large;
    top: 0;
    content: '-';
  }
}