@import "../../assets/styles/settings/_index";

.loadScreen {
  position: absolute;
  z-index: 4;
  height: calc(100vh);
  width: 100vw;
  /*background-image: url("../../assets/images/background.jpg");*/
  background-color: $brand-white;
  display: flex;
  justify-content: center;
  align-items: center;
  &.alternative {
    .loadScreen__icon {
      display: none;
    }
    .loadScreen__logo {
      display: none;
    }
    background-image: none;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &.grey {
    left: 80px;
    width: calc(100vw - 80px);
    .loadScreen__icon {
      display: none;
    }
    .loadScreen__logo {
      display: none;
    }
    background-image: none;
    background-color: $brand-white;
  }
}
.loadScreen__logo_wrapper {
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.loadScreen__logo {
  width: 300px;
  max-height: 300px;
  margin-bottom: 40px;
  
  &.loadScreen__logo_small {
    max-height: 50px;
  }
}

.loadScreen__logo_border {
  display:flex;
  flex-flow: row;
  width: 100%;
  height: 8px;
  margin: 15px 0px;

  .loadScreen__logo_border_first {
    width: 25%;
    height: 100%;
    background-color: lightgray;
  }
  .loadScreen__logo_border_second {
    width: 25%;
    height: 100%;
    background-color: $brand-color--alt;
  }
  .loadScreen__logo_border_third {
    width: 50%;
    height: 100%;
    background-color: gray;
  }
}

.loadScreen__mainArea {
  position: relative;
  text-align: center;
}

.connectionWarning {
  //transition: top 0.5s ease;
  position: absolute;
  //top: 100%;
  left: 50%;

  width: 300px;
  font-size: 18px;
  padding: 22px 40px;
  color: white;
  font-family: $font-sans;
  font-weight: normal;
  transform: translateX(-50%);

  //animation: moveInFromTop 1s linear infinite;
  animation: fadeIn 2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//@keyframes moveInFromTop { 0% { top: -1200px; } 100% { top: 300px; } }

.loadScreen__spinner_wrapper {display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & .loadScreen__spinner {
    height: 110px;
    width: 110px;
    border-bottom: 4px solid lightgray;
    border-left: 4px solid lightgray;
    border-right: 4px solid lightgray;
    border-top: 4px solid $brand-color--alt;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  & .loadScreen__icon {
    position: absolute;
    width: 40px;
    filter:brightness(0);
  }
}

.loadScreen__title {
  font-size: $font--large;
  font-weight: normal;
  margin-bottom: $margin;
}

.loadScreen__text {
  font-weight: normal;
  font-size: $font--small;
}

.loadScreen__performanceWarning {
  position: absolute;
  bottom: $nav-width;
  font-size: $font--small;
  font-weight: normal;
  animation: fadeIn $timing--quick linear;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
