@import '../../assets/styles/settings/_index';

.fullheader {
  width: 100%;
  text-align: center;

  position: absolute;
  z-index: 1;
  //left: $nav-width;
  top: $padding--large * 4;

  &.white {
    color: white;
  }
}

.fullheader__pretitle {
  text-transform: uppercase;
  font-weight: normal;
}

.fullheader__title {
  font-weight: normal;
  font-size: $font--largest;
  margin-bottom: 0;
  margin-top: $margin--large * 2;
  position: relative;

  //transform: translateX(-50%);

  @media(max-height: 850px) {
    font-size: $font--large;
  }
}

.fullheader__subtitle {
  font-weight: normal;
  font-size: $font--largest;
  @media(max-height: 850px) {
    font-size: $font--large;
  }
}