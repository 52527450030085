@import '../../assets/styles/settings/_index';

.modal {
  position: absolute;
  right:0;
  top: 0;
  height: 100%;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  z-index: 11;
}

.modal__inner {
  background: $brand-white;
  height: 80%;
  width: 80%;
  position: relative;
  top: 10%;
  left: 10%;
  z-index: 4;
  padding: $padding--large * 2;
  display: flex;
  justify-content: center;
  &.exit {
    height: 40%;
    width: 40%;
    left: 30%;
    top: 30%;
  }
}

.modal__back {
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-grey;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
  position: absolute;
  top: 36px;
  right: 60px;
  z-index: 10;
}

.modal__icon {
  position: absolute;
  height: 50px;
  width: 50px;
  cursor: pointer;
  left: 0;
}

.modal__lhs {
  width: 40%;
  height: 100%;
  padding: $padding;
  padding: $padding--large;
}

.modal__text {
  font-weight: normal;
  font-size: $font--small;
}

.modal__rhs {
  width: 60%;
  height: 100%;
  padding: $padding--large;
  display: flex;
  flex-direction: column;
  &.digital360 {
    .modal__image {
      height: 100%;
    }
  }
}

.modal__image {
  width: 100%;
  margin-top: $margin--large;
  top: 0;
  height: 40%;
  min-height: 280px;
  object-fit: cover;
  @media (min-width: 1400px) {
    max-height: 400px;
  }
}

.modal__columnArea {
  display: flex;
}

.modal__column {
  width: calc(100% / 3);
  flex-grow: 1;
  border: 1px solid $brand-grey--light;
  margin-top: $margin;
  &:not(:last-of-type) {
    margin-right: $margin;
  }
}

.modal__columnHeader {
  background: $brand-grey--light;
  height: 70px;
  padding: $padding--small;
}

.modal__columnTitle {
  font-size: $font--smallHeader;
  font-weight: normal;
  margin: 0;
}

.modal__columnSubtitle {
  font-size: $font--small;
  font-weight: normal;
  margin: $margin--small 0;
}

.modal__columnList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: $padding;
  font-size: $font--small;
  font-weight: normal;
}

.modal__columnItem {
  margin-bottom: $margin;
}

.modal__list {
  margin: 0;
	padding-left: $padding--large;
	list-style-type: none;
  margin-top: $margin--large * 2;
}

.modal__listItem {
  font-weight: normal;
	margin-bottom: $margin--small;
	position: relative;
	&::before {
		content: '-';
    left: -18px;
    position: absolute;
    top: 0; 
	}
}

.modal__exit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal__exitIcon {
  height: 15%;
  width: 15%;
  margin-bottom: 5%;
}

.modal__exitHeader {
  margin: 0;
  font-weight: normal;
  max-width: 80%;
  text-align: center;
  font-size: $font--large;
}

.modal__exitOptions {
  display: flex;
  width: 60%;
  justify-content: space-around;
  margin: $margin--large 0;

  button {
    margin: 5px;
  }
}

.modal__exitButton {
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-white;
  background: $brand-color--alt;
  height: 40px;
  width: 120px;
  border-radius: 0px;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-right: 0;
  &.cancel {
    text-transform: uppercase;
    background-color: #eee;
    height: 40px;
    width: 110px;
    border-radius: 0px;
    padding: 12px 8px;
    letter-spacing: 1px;
    color: black;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    outline: inherit;
    border: none;
  }
}

.modal__feedback {
  text-transform: uppercase;
  font-weight: normal;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $margin;
  margin-bottom: 0;
}