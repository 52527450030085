@import "../../assets/styles/settings/_index";

.fullScreen {
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  &.kenBurns {
    .fullScreen__image {
      animation: kenBurns2 6s linear;
    }
  }
  .standard__lhs {
    width: 100%;
    @media (max-height: 768px) {
      padding: 30px;
      padding-top: 60px;
      padding-left: 50px;
    }
  }
  &.slimContent {
    .standard__lhs {
      width: 40%;
      @media (max-height: 768px) {
        padding: 30px;
        padding-top: 60px;
        padding-left: 50px;
      }
    }
  }
}

.fullScreen__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover;
  @media (max-height: 768px) {
    top: -5%;
    object-fit: contain;
  }

  @media (min-height: 768px) and (max-height: 960px) {
    top: -5%;
    object-fit: contain;
  }

  @media (min-height: 1190px) {
    object-fit: contain;
  }
}

.fullScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover;
  &.contain {
    object-fit: contain;
  }
  &.lighten {
    opacity: 0.3;
  }
}

.fullScreen__explore {
  position: absolute;
  right: 12%;
  top: 54.5%;
  @media (max-height: 850px) {
    top: 54%;
    right: 10%;
  }
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  padding: $padding;
  letter-spacing: 2px;
  color: $brand-white;
  background-color: $brand-color--alt;
  border: none;
  font-size: $font--smallest;
  font-weight: normal;
  font-family: $font-sans;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  z-index: 10;
  bottom: $margin--large;

  line-height: 55px;
}

@keyframes kenBurns2 {
  0% {
    transform-origin: bottom right;
    transform: scale(1);
  }
  25% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
