@import "../../assets/styles/settings/_index";

.stageTracker {
  height: 100%;
  width: $nav-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  padding: calc(100px - 0.1%) 0;
  @media (max-height: 768px) {
    width: 80px;
    padding: calc(90px - 0.1%) 0;
  }
}

.stageTracker__line {
  position: relative;
  width: 2px;
  margin-bottom: $margin--small;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.hidden {
    display: none;
  }
  background: $brand-grey;
}

.stageTracker__highlight {
  width: 2px;
  height: 0;
  margin-bottom: $margin--small;
  background: $brand-color--alt;
  transition: height $timing--quick linear;
  &.on {
    height: 100%;
  }
}

.stageTracker__arrowIcon {
  position: absolute;
  width: 50%;
  &.red {
    transform: rotate(270deg);
  }
}

.stageTracker__arrow {
  position: absolute;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-grey;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
}

.nextProduct__arrow,
.stageTracker__arrow {
  background-color: transparent;

  &.up {
    transform: rotate(180deg);
    top: $margin--large * 2.5;
    @media (max-height: 768px) {
      top: $margin--large * 1.5;
    }

  }
  &.down {
    transform: rotate(0deg);
    bottom: 28px;
    @media (max-height: 768px) {
      bottom: 10px;
    }
  }
  &.right {
    transform: rotate(270deg);
  }
  &.left {
    transform: rotate(90deg);
  }
  &.hidden {
    display: none;
  }

  img{
    border: 2px solid #ffffff;
    filter: brightness(0.7);
    cursor: pointer;
  }
  img.grey,
  img.red,
  img.white,
  img.disabled {
    border-radius: 0px;
  }
  img.white {
    display: none;
  }
  img.disabled {
    background-color: #d2d2d2;
    border: 2px solid #d2d2d2;
    filter: brightness(1);
    cursor: default;
  }
  img.red
  {
    filter: brightness(1);
    border: 2px solid $brand-color--alt;
    background-color: $brand-color--alt;
  }
  &:hover {
    img.disabled {
      display: block;
    }
    img.grey {
      display: none;
    }
    img.white {
      display: block;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
    img.red {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
  }
  &:active {
    img.white {
      animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
    }
    img.grey {
      animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
    }
    img.red {
      animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
    }
  }
}

@keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1),
      0 0 0 10px rgba(255, 0, 15, 0.15);
  }
}
@keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1),
      0 0 0 10px rgba(172, 172, 172, 0.1);
  }
}

.stageTracker__progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stageTracker__progressTextContainer {
  display: inline-block;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: $margin--large * 5;
  width: 500px;
  opacity: 0;
  transition: opacity $timing--quick linear;
  &.show {
    opacity: 1;
  }
}

.stageTracker__progressText {
  margin-bottom: 0;
  font-size: $font--small;
  text-transform: none;
  letter-spacing: 0;
  &.black {
    color: $brand-black;
    margin-left: $margin--small;
  }
  &.grey {
    font-weight: normal;
    color: $brand-grey;
  }
}

.stageTracker.hideBars {
  .stageTracker__arrow.up,
  .stageTracker__line {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotateBack {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
  }
}
