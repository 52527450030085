@import '../../assets/styles/settings/_index';

.stage {
	position: absolute;
	top: 0;
	z-index: 3;
	height: 100%;
	width: 100%;
	background: transparent;
	animation: fadeIn $timing--quick linear;
	&.stage--portfolio {
		.stage__header {
			display: none;
		}
		.stage__image {
			width: 80%;
		}
	}
}

.stage__header {
	z-index: 3;
	font-size: $font--small;
	position: absolute;
	&::before {
		height: 5px;
    width: 40px;
    background: $brand-color--alt;
    position: absolute;
    content: "";
		top: -$margin;
	}
}

.stage__image {
	width: 100%;
	height: 100%;
}

.stage__imageArea {
	bottom: 0;
	padding: 0;
	margin: 0;
	position: absolute;
	width: 80%;
}

.imageArea__thumb {
	display: inline-block;
	cursor: pointer;
	margin: 0 0 $margin--large $margin--large;
	img {
		width: 200px;
		height: 100px;
	}
	&.isActive {
		img {
			border: 2px solid $brand-color--alt;
		}
	}
}

.fullOverlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

.stage__back {
  position: absolute;
  top: 32px;
  left: $margin--large * 3;
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-grey;
  background: none;
  border: none;
  padding: 0;
  font-size: $font--small;
  font-family: $font-sans;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
  z-index: 4;
}

.stage__backIcon {
  width: 7px;
  margin-right: $margin--small;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}	