@charset "UTF-8";

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; 
  }
  to {
    top: 0; 
  } 
}

@keyframes moveinDown {
  from {
    top: 100vh; 
  }
  to {
    top: 0; 
  } 
}

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; 
  }
  to {
    top: 0; 
  } 
}

@keyframes moveinUp {
  from {
    top: -100vh; 
  }
  to {
    top: 0; 
  } 
}

body {
  margin: 0; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0; 
}

figure {
  margin: 0; 
}

@font-face {
  font-family: "ABBvoice";
  src: url(../../static/media/ABBvoice_Bd.f8c29774.ttf) format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url(../../static/media/ABBvoice_Md.6fcedc8a.ttf) format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url(../../static/media/ABBvoice_Rg.a847154d.ttf) format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url(../../static/media/ABBvoice_Lt.1a325557.ttf) format("truetype");
  font-weight: normal; 
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none; 
}

body {
  background: #fff;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none; 
}

a {
  color: #fff;
  text-decoration: none; 
}
a:hover, a:focus {
  outline: none;
  text-decoration: underline; 
}

.link--no-style:hover, .link--no-style:focus {
  text-decoration: none; 
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px; 
}
.visuallyHidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix::before, .clearfix::after {
  content: '';
  display: table; 
}

.clearfix::after {
  clear: both; 
}

.app {
  background: red;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none; }

*:focus {
  outline: none; }

.app__shell {
  background: #eee;
  position: relative; }

.calloutArea {
  font-weight: normal; }
  .calloutArea.calloutTitleSmall .calloutArea__title {
    font-weight: normal;
    margin: 20px 0;
    font-size: 14px; }

.calloutArea__main {
  max-width: 85%; }
  .calloutArea__main.horizontal {
    border-top: 2px solid #eee;
    display: flex;
    justify-content: start;
    padding-top: 20px; }
    @media (min-width: 1600px) {
      .calloutArea__main.horizontal .calloutArea__callout {
        padding-right: 60px; } }
  .calloutArea__main.noPadding .calloutArea__callout {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0; }
  .calloutArea__main.noPadding .calloutArea__boldText {
    font-size: 16px; }
    @media (min-height: 850px) {
      .calloutArea__main.noPadding .calloutArea__boldText {
        font-size: 20px; } }

.calloutArea__main > * {
  margin-top: 10px;
  margin-bottom: 10px; }

.calloutArea__title {
  font-weight: normal;
  margin: 20px 0;
  font-size: 16px; }

.calloutArea__callout {
  display: flex;
  align-items: center;
  padding: 16px;
  max-width: 440px;
  min-height: 55px;
  background-repeat: no-repeat;
  padding-left: calc(20px + 44px);
  background-position: 0px 10px;
  background-size: 44px 44px;
  background-position: 0% calc(50% - 10px);
  overflow: visible; }
  @media (max-height: 800px) {
    .calloutArea__callout {
      background-position: 0% 50%; } }
  @media (max-height: 850px) and (max-width: 1370px) {
    .calloutArea__callout {
      padding-top: 0px;
      padding-bottom: 15px;
      background-position: 0px 5px; } }

.calloutArea__icon {
  width: 44px;
  height: 44px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-self: flex-start; }

.calloutArea__boldText {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0; }

.calloutArea__text {
  font-size: 14px;
  margin: 0; }

.calloutArea__textArea {
  margin-bottom: 20px; }
  @media (max-height: 800px) {
    .calloutArea__textArea {
      margin-bottom: 0; } }

.calloutArea__list {
  padding-left: 20px;
  list-style: none;
  margin-bottom: 0; }

.calloutArea__listItem {
  position: relative; }
  .calloutArea__listItem::before {
    position: absolute;
    left: -20px;
    top: 0;
    content: "-"; }

.CalloutMulti {
  font-weight: normal; }

.CalloutMulti__main {
  padding-bottom: 20px; }
  .CalloutMulti__main.horizontal {
    border-top: 2px solid #eee;
    display: flex;
    justify-content: space-between;
    padding-top: 20px; }
  .CalloutMulti__main.noPadding .CalloutMulti__callout {
    padding: 0; }

.CalloutMulti__title {
  font-weight: normal; }

.callOutRow__break {
  color: #6e6e6e;
  letter-spacing: 1px;
  font-weight: normal;
  width: 100%;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 10px;
  margin-bottom: 8px; }

.CalloutMultiRow {
  display: flex;
  flex-direction: row; }

.CalloutMulti__callout {
  width: 49%;
  flex: 1 1;
  padding: 10px;
  max-width: 220px;
  display: inline-flex;
  flex-wrap: nowrap;
  padding-left: 55px;
  background-repeat: no-repeat;
  background-position: 0px 10px;
  background-size: 44px 44px; }
  @media (max-height: 880px) and (max-width: 1650px) {
    .CalloutMulti__callout {
      padding-top: 5px;
      padding-bottom: 5px; } }
  .CalloutMulti__callout h3 {
    font-size: 24px;
    margin-top: 0;
    font-weight: normal; }
    @media (max-height: 880px) and (max-width: 1650px) {
      .CalloutMulti__callout h3 {
        font-size: 14px; } }

.CalloutMulti__icon {
  width: 44px;
  height: 44px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-self: flex-start; }

.CalloutMulti__boldText {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 5px; }

.CalloutMulti__text {
  font-size: 14px;
  margin: 0; }

.CalloutMulti__textArea {
  margin-bottom: 20px; }
  @media (max-height: 880px) and (max-width: 1450px) {
    .CalloutMulti__textArea {
      margin-bottom: 0; } }

.CalloutMulti__list {
  padding-left: 20px;
  list-style: none; }

.CalloutMulti__listItem {
  position: relative; }
  .CalloutMulti__listItem::before {
    position: absolute;
    left: -20px;
    top: 0;
    content: '-'; }

.iconList {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 160px;
  margin-top: 150px; }
  @media (min-height: 768px) {
    .iconList {
      margin-top: 110px; } }
  .iconList .iconList__column {
    flex: 1 1;
    text-align: center;
    font-size: 18px;
    font-weight: normal; }
    .iconList .iconList__column hr {
      height: 2px;
      background-color: #eee;
      border: 0;
      width: 40px; }
    .iconList .iconList__column .iconList__icon-container {
      width: 80px;
      height: 80px;
      margin-left: auto;
      margin-right: auto;
      padding: 12.8px;
      margin-bottom: 5px; }
      .iconList .iconList__column .iconList__icon-container .iconList__icon {
        width: 40px;
        margin-top: 6px;
        vertical-align: middle; }
    .iconList .iconList__column .iconList__valueText {
      font-size: 12px; }
    .iconList .iconList__column .iconList__value,
    .iconList .iconList__column .iconList__header {
      font-weight: normal;
      padding: 10px 0;
      font-size: 22px; }

.contentLinkArea {
  position: absolute;
  bottom: 100px;
  width: calc(100% - 80px - 20px * 3); }

.contentLinkArea__link {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #eee; }
  .contentLinkArea__link:last-of-type {
    border-bottom: 1px solid #eee; }

.contentLinkArea__text {
  font-weight: normal;
  margin-left: 20px; }

.contentLinkArea__image {
  width: 30px;
  height: 30px; }

.contentLinkArea__arrow {
  position: absolute;
  right: 0;
  width: 40px;
  height: 15px; }

.panel {
  color: #000;
  font-size: 14px;
  margin-top: 40px; }
  @media (max-height: 850px) {
    .panel {
      margin-top: 20px; } }
  .panel.white {
    color: #fff; }

.panel__para.gap .panel__paraHeader {
  margin-bottom: 15px; }

.panel__para.noMargin .panel__paraText {
  margin: 0; }

.panel__para.smallMargin .panel__paraText {
  margin-bottom: 10px; }

.panel__para.largeMargin .panel__paraHeader {
  margin-bottom: 20px; }

.panel__paraHeader {
  margin: 0;
  font-weight: normal;
  font-size: 20px; }

.panel__paraText {
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 20px; }

.panel__list {
  margin: 0;
  padding-left: 10px;
  list-style: none;
  margin-bottom: 20px; }
  .panel__list.wheelList {
    padding-left: 20px;
    padding-top: 20px; }

.panel__listHeader {
  font-size: 16px;
  font-weight: normal; }

.panel__listItem {
  font-weight: normal;
  margin-bottom: 5px;
  position: relative; }
  .panel__listItem::before {
    position: absolute;
    left: -10px;
    top: 0;
    content: '-'; }
  .panel__listItem.wheelList {
    text-indent: -5px; }

.panel__intro {
  margin-bottom: 20px; }

.panel__introTitle {
  font-weight: normal;
  margin: 0;
  font-size: 20px; }

.panel__introSubtitle {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px; }

.panel__introBody {
  font-size: 14px;
  font-weight: normal; }

.header {
  max-width: 450px; }
  .header.fullscreen {
    position: absolute;
    left: 80px;
    top: 80px; }
  .header.unity {
    position: absolute;
    left: 80px;
    top: 80px; }
  .header.white .header__pretitle {
    color: #fff; }
  .header.white .header__title {
    color: #fff; }
    .header.white .header__title::before {
      color: #fff; }
  .header.white .header__subtitle {
    color: #fff; }
  .header.wide {
    max-width: 1000px; }
    .header.wide .header__title {
      z-index: 1; }
  .header.absolute {
    position: absolute;
    z-index: 1;
    left: 80px;
    top: 80px; }

.overdark .header {
  color: white; }
  .overdark .header::before {
    color: white; }

.header__pretitle {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 20px;
  color: #ED1D35;
  margin-bottom: 0; }

.header__title {
  font-weight: normal;
  font-size: 44px;
  margin-bottom: 0;
  position: relative; }
  @media (max-height: 960px) {
    .header__title {
      font-size: 32px; } }

.header__subtitle {
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 0;
  position: relative; }
  @media (max-height: 960px) {
    .header__subtitle {
      font-size: 14px; } }

.button {
  position: absolute;
  right: 80px;
  top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 40px;
  width: 110px;
  border-radius: 0px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 12px;
  cursor: pointer;
  outline: inherit; }
  .button.operate-button {
    right: 245px;
    background-color: #eee;
    color: black;
    top: 35px;
    height: 4.7vh;
    font-size: 12px;
    font-weight: normal; }
    .button.operate-button:hover {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); }
  .button.alt {
    background: #eee;
    color: #a9a9a9; }
  .button.back {
    left: 80px;
    z-index: 10;
    text-align: center;
    top: 35px;
    font-size: 14px;
    color: #acacac;
    border: 2px solid #acacac;
    border-radius: 0px;
    padding: 1px 20px 0 20px; }
    @media (max-height: 768px) {
      .button.back {
        left: 60px; } }
    .button.back:hover {
      background: #acacac;
      color: #fff; }
  .button.replay {
    background: #eee;
    color: #a9a9a9;
    right: 280px;
    border: 1px solid #dbdbdb; }
  .button.replay:hover {
    box-shadow: 0px 2px 8px rgba(200, 200, 200, 0.3); }
  .button.close {
    right: 40px;
    background: #eee;
    color: #a9a9a9; }
    .button.close.timeline {
      top: 0; }
  .button.unityClose {
    right: 160px;
    background: #eee;
    color: #a9a9a9;
    border: 1px solid #dbdbdb; }
  .button.unityClose:hover {
    box-shadow: 0px 2px 8px rgba(200, 200, 200, 0.3); }
  .button.onboarding {
    right: 25px;
    background: #ED1D35;
    color: #fff;
    z-index: 2;
    top: 30px; }
  .button.hidden {
    display: none; }
  .button.switch {
    top: 0;
    left: 0;
    background: #eee;
    color: #a9a9a9; }
    .button.switch.active {
      background: #ED1D35;
      z-index: 1;
      color: #fff; }
    .button.switch.conventional {
      width: 160px; }
    .button.switch.digital {
      width: 110px;
      left: 150px; }
  .button.mapNav {
    text-transform: initial;
    white-space: nowrap;
    width: auto;
    padding: 0 20px; }
    .button.mapNav.active {
      background-color: #ED1D35;
      color: white; }
    .button.mapNav.inactive {
      background-color: #eee;
      color: black; }
    .button.mapNav span {
      font-weight: normal; }
      .button.mapNav span strong {
        font-weight: normal; }
  .button.moreInfo-button {
    background-color: #eee;
    color: black; }
    .button.moreInfo-button.active button__text::before {
      content: "x"; }

.button__icon {
  height: 10px;
  width: 10px;
  margin-right: 5px; }

.textDisplay {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  margin-top: 4%; }

.textDisplay__container {
  height: auto;
  width: auto;
  margin: 0 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; }
  .textDisplay__container:first-of-type {
    margin-left: 0; }
  .textDisplay__container:last-of-type {
    margin-right: 0; }

.textDisplay.has-2 {
  margin-top: 2.5%; }
  @media (max-height: 700px) {
    .textDisplay.has-2 {
      margin-top: 0; }
      .textDisplay.has-2 .textDisplay__textArea {
        padding-bottom: 20px; } }
  .textDisplay.has-2 .textDisplay__container {
    width: 47%; }

.textDisplay.has-3 .textDisplay__container {
  width: 30%; }

.textDisplay__image {
  width: 100%;
  min-width: 50%;
  height: auto;
  max-height: 275px;
  object-fit: cover; }

.textDisplay__textArea {
  padding: 40px;
  background: #fff; }

.textDisplay__header {
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 16px; }
  @media (min-height: 850px) {
    .textDisplay__header {
      font-size: 20px;
      margin-bottom: 20px; } }

.textDisplay__text {
  max-width: 470px;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px; }
  @media (min-height: 850px) {
    .textDisplay__text {
      font-size: 14px; } }

.textDisplay__list {
  margin: 0;
  margin-bottom: 10px;
  list-style: square inside url("data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==");
  padding: 0 10px;
  font-size: 12px; }
  @media (min-height: 850px) {
    .textDisplay__list {
      font-size: 14px; } }

.textDisplay__listHeader {
  font-size: 14px;
  margin-left: -10px;
  margin-bottom: 5px;
  font-weight: normal; }
  @media (min-height: 850px) {
    .textDisplay__listHeader {
      font-size: 16px; } }

.textDisplay__listItem {
  text-indent: -10px;
  font-weight: normal; }

.fullheader {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 80px; }
  .fullheader.white {
    color: white; }

.fullheader__pretitle {
  text-transform: uppercase;
  font-weight: normal; }

.fullheader__title {
  font-weight: normal;
  font-size: 44px;
  margin-bottom: 0;
  margin-top: 40px;
  position: relative; }
  @media (max-height: 850px) {
    .fullheader__title {
      font-size: 32px; } }

.fullheader__subtitle {
  font-weight: normal;
  font-size: 44px; }
  @media (max-height: 850px) {
    .fullheader__subtitle {
      font-size: 32px; } }

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 0;
  transition: opacity 2000ms linear; }
  .videoOverlay.exiting {
    opacity: 0; }
  .videoOverlay .overlay__button.video-overlay {
    position: absolute;
    top: 68%;
    left: 86.5%;
    width: 13%;
    line-height: 54px;
    -webkit-animation: fadeIn 1000ms linear;
            animation: fadeIn 1000ms linear;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .videoOverlay .overlay__button.video-overlay.substations0 {
      top: 52.5%;
      right: 9%; }
    .videoOverlay .overlay__button.video-overlay.substations1 {
      top: 70%;
      right: 9%; }
    .videoOverlay .overlay__button.video-overlay:hover {
      box-shadow: 0px 2px 8px rgba(200, 200, 200, 0.3); }

.videoOverlay__body {
  color: #fff;
  max-width: 30%;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  z-index: 4;
  top: 240px;
  left: 35%;
  text-align: center; }
  .videoOverlay__body.noSubTitle {
    top: 187px; }

.videoOverlay__imageArea {
  position: relative;
  height: 100%;
  width: 100%; }

.buttonContainer,
.videoOverlay__video {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-top: 0;
  object-fit: cover !important;
  width: 100%;
  height: 100vh; }

.buttonSubContainer {
  width: 1840px;
  height: 1080px;
  object-fit: cover !important;
  background-color: rgba(255, 0, 0, 0.5);
  position: relative; }

.videoOverlay__hidden {
  display: none; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.unityDisplay {
  position: relative;
  height: 100%;
  width: 100%;
  display: none; }
  .unityDisplay.fullWidth .unityDisplay__lhs {
    width: 0%; }
  .unityDisplay.fullWidth .unityDisplay__rhs {
    width: 100%;
    transition: none;
    left: 0; }

.unityDisplay__lhs {
  position: absolute;
  left: 0;
  top: 0;
  padding: 40px;
  width: 30%; }

.unityDisplay__rhs {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: #fff;
  z-index: 1;
  width: 70%;
  height: 100%;
  left: 30%;
  top: 0;
  transition: all 500ms linear; }
  .unityDisplay__rhs.open {
    width: 100%;
    left: 0; }

.unityDisplay__back {
  position: fixed;
  top: 30px;
  right: 5px;
  width: 80px;
  text-transform: uppercase;
  font-weight: normal;
  color: #acacac;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }
  .unityDisplay__back.exiting {
    opacity: 0; }
  .unityDisplay__back.entering {
    -webkit-animation: fadeIn 1000ms linear;
            animation: fadeIn 1000ms linear; }

.unityDisplay__backIcon {
  width: 60%; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.home {
  height: 100%;
  width: 100%;
  text-align: left;
  position: absolute;
  z-index: 2; }

.home__headerArea {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  transition: all 500ms linear;
  opacity: 0; }
  .home__headerArea.show {
    opacity: 1;
    z-index: 3; }

.home__subtitle,
.home__preHeader {
  position: relative;
  z-index: 2;
  font-weight: normal;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 2px; }

.home__header {
  color: #fff;
  z-index: 2;
  margin-top: 20px;
  font-size: 44px;
  max-width: 700px;
  text-align: left;
  font-weight: normal; }
  .home__header.onboarding {
    margin: 40px 0;
    position: relative; }

.home__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.home__onboardingImage {
  position: absolute;
  left: 0;
  top: 13.5%;
  height: 75%;
  z-index: 2; }

.home__onboarding {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2; }

.home__number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0; }

.home__onboardingNumberIcon {
  height: 40px;
  width: 40px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  line-height: 40px;
  text-align: center; }

.home__onBoardingNav {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 330px;
  top: 110px;
  left: -10px; }

.home__onBoardingNav__line {
  width: 50%;
  height: 2px;
  background: white; }

.home__onBoardingNav__numberIcon {
  height: 40px;
  width: 40px;
  position: relative; }

#onBoardingNav__1,
#onBoardingNav__2,
#onBoardingNav__3 {
  width: 100px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start; }

.home__onboardingText {
  font-weight: normal;
  font-size: 16px;
  color: #d2d2d2;
  max-width: 300px;
  margin-top: 15px;
  margin-bottom: 0; }

.home__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); }
  .home__overlay.darker {
    background: rgba(0, 0, 0, 0.9); }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.standard {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  padding-right: 160px;
  z-index: 1; }
  .standard.fullscreen {
    padding-right: 0;
    -webkit-animation: fadeIn 1000ms linear;
            animation: fadeIn 1000ms linear; }
  .standard.blackout {
    background-color: #363636; }
    .standard.blackout header {
      color: white; }
      .standard.blackout header .header__title::before {
        color: white; }
  .standard.fullHeight .standard__lhs {
    padding-top: 30vh; }
  .standard.noBackground {
    background: none; }
  .standard.fullWidth {
    flex-direction: column; }
  .standard.fullSizeImage {
    width: 100%; }
    .standard.fullSizeImage .standard__video {
      padding-right: 80px; }
  @media (max-height: 900px) {
    .standard.addScroll .panel {
      height: calc(100% - 275px);
      overflow-y: scroll; }
      .standard.addScroll .panel .panel__paraText {
        padding-right: 3px; } }
  .standard.reverse {
    flex-direction: row-reverse; }
  .standard.fadeDelay .standard__lhs.invisible {
    opacity: 0; }
  .standard.fadeDelay .standard__lhs.visible {
    -webkit-animation: fadeIn 1000ms linear;
            animation: fadeIn 1000ms linear;
    opacity: 1; }

.standard__lhs {
  position: relative;
  height: 100%;
  width: 40%;
  padding: 60px;
  padding-top: 80px;
  padding-left: 80px; }
  @media (max-height: 768px) {
    .standard__lhs {
      width: 50%;
      padding-top: 60px;
      padding-left: 60px; } }
  @media (max-height: 1040px) {
    .standard__lhs {
      width: 60%; } }
  .standard__lhs.right-side {
    left: 65%; }
  .standard__lhs.fullWidth {
    width: 100%;
    height: auto;
    padding-bottom: 0; }
  .standard__lhs.wideLeft {
    min-width: 45%; }
  .standard__lhs .standard__icon {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    margin-bottom: 12px; }

#section7-group0-stage1 .standard__lhs {
  width: 40%; }

.standard__rhs {
  position: relative;
  height: 100%;
  width: 60%; }
  .standard__rhs.wideLeft {
    width: 60%;
    z-index: -1; }
    .standard__rhs.wideLeft .standard__video {
      padding: calc((20px * 4) + 7%) 0; }
  .standard__rhs.fullWidth {
    width: 100%;
    height: auto;
    padding-left: 80px; }

.standard__video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  -webkit-filter: brightness(105%);
          filter: brightness(105%); }

.standard__image {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 100px 0; }
  @media (min-height: 768px) {
    .standard__image {
      object-fit: contain; } }
  .standard__image.diagram {
    object-fit: none;
    height: 100%; }
  .standard__image.right-side {
    right: 80%; }
  @media (max-height: 900px) {
    .standard__image.contain {
      object-fit: contain; } }
  .standard__image.cover {
    object-fit: cover; }
  .standard__image.dark {
    -webkit-filter: brightness(20%);
            filter: brightness(20%); }
  .standard__image.invisible {
    opacity: 0; }
  .standard__image.visible {
    -webkit-animation: fadeIn 500ms linear;
            animation: fadeIn 500ms linear;
    opacity: 1; }

.standard__image__overlay {
  background-color: rgba(0, 0, 0, 0.8); }

.standard__image .standard__altMediaArea {
  position: absolute;
  height: 70%;
  top: 30%;
  padding-left: 24px;
  padding-right: 20px; }
  .standard__image .standard__altMediaArea.fullSizeImage {
    top: 20%;
    height: 80%; }
  .standard__image .standard__altMediaArea.fullSizeVideo {
    height: 100%;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: -1; }
  .standard__image .standard__altMediaArea.cover .standard__video {
    object-fit: cover; }
  .standard__image .standard__altMediaArea.contain .standard__video {
    object-fit: contain; }

.standard__altImage {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover; }
  .standard__altImage.ability {
    padding-bottom: 40px; }
  .standard__altImage.svg {
    top: 10%;
    z-index: 3;
    object-fit: contain;
    padding-left: 80px;
    padding-right: 160px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: calc(100vw - 80px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 500ms linear;
  opacity: 0; }
  .overlay.exiting {
    opacity: 0; }
  .overlay.entering {
    opacity: 0; }
  .overlay.entered {
    opacity: 1; }
  .overlay.unity {
    width: 100%; }

.overlay__title {
  color: #fff;
  margin: 40px 0;
  max-width: 560px;
  text-align: center;
  font-size: 44px; }

.overlay__iconArea {
  color: #fff;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.overlay__icon {
  width: 50px;
  height: auto;
  margin-bottom: 20px; }

.overlay__button {
  height: 50px;
  width: 150px;
  border-radius: 0px;
  background: #ED1D35;
  color: #fff;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit; }
  .overlay__button.sequence {
    position: absolute;
    top: 57%;
    right: 0.6%;
    width: 12%; }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.timeline {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  transition: left 500ms linear; }

.timeline__column {
  height: 100%;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column; }

.timeline__item {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 60px;
  padding-top: 30%;
  align-items: center;
  overflow: hidden; }
  .timeline__item.inversePadding {
    padding-top: calc(30% - (20px * 3));
    padding-bottom: 120px; }

.timeline__year {
  font-size: 60px;
  font-weight: normal;
  margin-bottom: 10px;
  width: 100%; }
  @media (min-height: 800px) {
    .timeline__year {
      font-size: 70px; } }

.timeline__text {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 10px;
  width: 100%; }
  @media (min-height: 800px) {
    .timeline__text {
      margin-bottom: 20px; } }

.timeline__image {
  position: absolute;
  top: 38%;
  width: calc(100% - 120px);
  object-fit: scale-down; }
  @media (max-height: 800px) {
    .timeline__image {
      top: 45%; } }

.timeline__nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  bottom: 20px;
  left: calc(10% + 40px);
  -webkit-animation: fadeIn 500ms linear;
          animation: fadeIn 500ms linear; }

.timeline__progressBarContainer {
  height: 3px;
  margin-bottom: 3px;
  width: 80%;
  background: #acacac; }

.timeline__progressBar {
  height: 3px;
  background: #ED1D35;
  transition: width 500ms linear; }

.timeline__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; }

.timeline__icon {
  height: 50px;
  width: 50px;
  cursor: pointer; }
  .timeline__icon.right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.timeline__video {
  object-fit: cover;
  width: 100%;
  z-index: -3; }
  .timeline__video.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  .timeline__video.small {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0 60px;
    object-fit: scale-down; }

.timeline__videoOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -1; }

.timeline__imageOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -1; }

.timeline__backgroundImageOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -2; }

.timeline__backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  object-fit: cover; }
  .timeline__backgroundImage.animating {
    -webkit-animation: kenBurns 40s linear;
            animation: kenBurns 40s linear; }
    .timeline__backgroundImage.animating.delay {
      -webkit-animation-delay: 40s;
              animation-delay: 40s; }

.timeline__back {
  position: fixed;
  right: 5px;
  width: 80px;
  text-transform: uppercase;
  font-weight: normal;
  color: #acacac;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }

.timeline__backIcon {
  width: 60%; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes kenBurns {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes kenBurns {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.flexible {
  position: relative;
  height: 100%;
  width: calc(100% - 80px);
  padding-top: 80px;
  padding-right: 80px;
  padding-left: 80px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column; }

.flexible__top {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  height: 55%; }
  @media (max-height: 850px) {
    .flexible__top {
      margin-top: 5px;
      margin-bottom: 5px; } }

.flexible__box {
  width: calc((100% - (4 * 20px)) / 4);
  border: 2px solid #eee; }
  .flexible__box:not(:last-of-type) {
    margin-right: 40px; }
    @media (max-height: 850px) {
      .flexible__box:not(:last-of-type) {
        margin-right: 20px; } }
  .flexible__box:first-of-type {
    margin-right: 80px; }
    @media (max-height: 850px) {
      .flexible__box:first-of-type {
        margin-right: 40px; } }
    .flexible__box:first-of-type .flexible__boxImage {
      padding: 20px; }
      @media (min-height: 910px) and (min-width: 1700px) {
        .flexible__box:first-of-type .flexible__boxImage {
          padding: 50px; } }

.flexible__boxImage {
  height: 150px;
  width: 100%;
  border-bottom: 2px solid #eee;
  object-fit: contain; }
  @media (max-height: 800px) {
    .flexible__boxImage {
      height: 100px; } }
  @media (min-height: 910px) and (min-width: 1700px) {
    .flexible__boxImage {
      height: 260px; } }

.flexible__boxTextArea {
  padding: 20px; }

.flexible__boxHeader {
  font-size: 20px;
  font-weight: normal; }
  @media (max-height: 900px) {
    .flexible__boxHeader {
      font-size: 16px; } }

.flexible__boxList {
  list-style: none;
  padding-left: 20px;
  margin: 0; }

.flexible__boxItem {
  position: relative;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px; }
  .flexible__boxItem::before {
    position: absolute;
    content: '-';
    left: -12px; }

.flexible__bottom {
  display: flex; }

.flexible__bottomLhs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(((100% - (4 * 20px)) / 4) + 80px); }
  @media (max-height: 850px) {
    .flexible__bottomLhs {
      width: calc(((100% - (4 * 20px)) / 4) + 50px); } }

.flexible__bottomRhs {
  width: 75%; }

.flexible__lhsText {
  font-weight: normal;
  font-size: 16px; }

.flexible__icons {
  border: 1px solid #000;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.flexible__item {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px; }

.flexible__itemImage {
  height: 40px;
  width: auto;
  margin-bottom: 5px; }
  @media (max-height: 850px) {
    .flexible__itemImage {
      height: 25px; } }

.flexible__itemTextArea {
  height: 40px; }

.flexible__itemLabel {
  font-size: 14px;
  max-width: 100px;
  font-weight: normal;
  margin: 0;
  text-align: center; }

.flexible__itemSubLabel {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  text-align: center; }

.flexible__smallPrint {
  font-size: 14px;
  margin: 10px 0; }
  @media (max-height: 850px) {
    .flexible__smallPrint {
      margin: 0; } }

.wheel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wheel__video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.wheel__circle {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #fff;
  height: 640px;
  width: 640px;
  top: calc(50% - (640px / 2) + 20px * 4);
  left: 80px;
  border-radius: 50%;
  overflow: hidden; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__circle {
      height: 500px;
      width: 500px;
      top: calc(50% - (640px / 2) + 20px * 6.5);
      left: 80px; } }

.wheel__inner {
  position: absolute;
  height: 40%;
  width: 40%;
  background: #fff;
  left: 30%;
  top: 30%;
  display: flex;
  border-radius: 50%;
  align-items: center;
  border: 1px solid #acacac; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__inner {
      height: 35%;
      width: 35%;
      left: 32.5%;
      top: 32.5%; } }

.wheel__innerText {
  text-align: center;
  font-size: 20px;
  margin-top: 20px; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__innerText {
      font-size: 14px; } }

.wheel__segment {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #eee; }
  .wheel__segment.selected {
    background: #fff; }
  .wheel__segment.top {
    padding-top: 20px; }
  .wheel__segment.left {
    padding-left: 20px; }
  .wheel__segment.bottom {
    padding-bottom: 20px; }
  .wheel__segment.right {
    padding-right: 20px; }

.wheel__segmentIcon {
  height: 50px;
  width: 50px; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__segmentIcon {
      height: 35px;
      width: 35px; } }

.wheel__segmentHeader {
  margin: 5px 0;
  font-size: 16px; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__segmentHeader {
      font-size: 14px; } }

.wheel__arrows {
  position: absolute;
  height: 100%;
  width: 100%; }

.wheel__button {
  z-index: 3;
  text-transform: uppercase;
  width: 100px;
  padding: 10px;
  letter-spacing: 1px;
  color: #fff;
  background-color: #ED1D35;
  border: none;
  font-size: 12px;
  font-weight: normal;
  font-family: "Arial, Helvetica, sans-serif";
  border-radius: 0px;
  cursor: pointer;
  outline: inherit; }
  @media (min-height: 830px) {
    .wheel__button {
      height: 40px;
      width: 120px;
      font-size: 14px; } }
  .wheel__button.disabled {
    background: #eee;
    color: #a9a9a9; }
  .wheel__button:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); }

.wheel__content {
  height: 400px;
  width: 400px;
  position: absolute;
  top: calc(50% - (400px / 2) + 20px * 4);
  left: calc((80px * 3) + 640px);
  z-index: 2;
  color: #fff; }
  @media (max-height: 850px) and (max-width: 1380px) {
    .wheel__content {
      left: calc((80px * 2) + 500px); } }

.wheel__contentTextArea {
  display: none; }
  .wheel__contentTextArea.showing {
    display: block;
    -webkit-animation: fadeIn 500ms ease-in;
            animation: fadeIn 500ms ease-in; }

.wheel__contentIconContainer {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }

.wheel__contentIcon {
  width: 50px;
  height: 50px; }

.wheel__contentTitle {
  font-size: 32px;
  font-weight: normal;
  margin: 0; }

.wheel__contentSubTitle {
  font-size: 32px;
  font-weight: normal;
  margin: 0; }

.wheel__contentNavigation {
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0; }

.wheel__contentProgress {
  font-size: 14px; }

.wheel__contentNavigationButton {
  height: 40px;
  width: 40px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0 10px; }
  .wheel__contentNavigationButton.right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.fullScreen {
  position: relative;
  width: 100%;
  height: 100%;
  position: relative; }
  .fullScreen.kenBurns .fullScreen__image {
    -webkit-animation: kenBurns2 6s linear;
            animation: kenBurns2 6s linear; }
  .fullScreen .standard__lhs {
    width: 100%; }
    @media (max-height: 768px) {
      .fullScreen .standard__lhs {
        padding: 30px;
        padding-top: 60px;
        padding-left: 50px; } }
  .fullScreen.slimContent .standard__lhs {
    width: 40%; }
    @media (max-height: 768px) {
      .fullScreen.slimContent .standard__lhs {
        padding: 30px;
        padding-top: 60px;
        padding-left: 50px; } }

.fullScreen__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover; }
  @media (max-height: 768px) {
    .fullScreen__video {
      top: -5%;
      object-fit: contain; } }
  @media (min-height: 768px) and (max-height: 960px) {
    .fullScreen__video {
      top: -5%;
      object-fit: contain; } }
  @media (min-height: 1190px) {
    .fullScreen__video {
      object-fit: contain; } }

.fullScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  object-fit: cover; }
  .fullScreen__image.contain {
    object-fit: contain; }
  .fullScreen__image.lighten {
    opacity: 0.3; }

.fullScreen__explore {
  position: absolute;
  right: 12%;
  top: 54.5%;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  padding: 10px;
  letter-spacing: 2px;
  color: #fff;
  background-color: #ED1D35;
  border: none;
  font-size: 12px;
  font-weight: normal;
  font-family: "Arial, Helvetica, sans-serif";
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  z-index: 10;
  bottom: 20px;
  line-height: 55px; }
  @media (max-height: 850px) {
    .fullScreen__explore {
      top: 54%;
      right: 10%; } }

@-webkit-keyframes kenBurns2 {
  0% {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes kenBurns2 {
  0% {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.intro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%; }

.intro__image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  -webkit-animation: kenBurns 60s linear;
          animation: kenBurns 60s linear;
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

#section2-group3-stage0 .intro__image {
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.intro__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }

.intro__title {
  color: #fff;
  font-size: 44px;
  font-weight: normal;
  z-index: 2;
  margin-bottom: 0; }

.intro__subtitle {
  font-weight: normal;
  color: #fff;
  font-size: 32px;
  z-index: 2;
  margin-bottom: 20px; }

.intro__text {
  line-height: 1.5;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  max-width: 500px;
  text-align: center;
  z-index: 2; }

@-webkit-keyframes kenBurns {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes kenBurns {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.NavBox {
  position: relative;
  width: 12vw;
  display: inline-block;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  color: #000;
  text-decoration: none !important;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .NavBox.large {
    height: 100%;
    min-height: 405px; }
  .NavBox.small {
    height: 30vh; }
  .NavBox:hover {
    box-shadow: 0 10px 28px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.08); }
    .NavBox:hover .NavBox__progressBar {
      width: 100%; }
  .NavBox:nth-child(5n) {
    margin-right: 0; }
  .NavBox.caseStudy {
    height: 100% !important; }
  .NavBox.width:not(:last-of-type) {
    margin-right: 80px; }
    .NavBox.width:not(:last-of-type).smallRightMargin {
      margin-right: 40px; }

.NavBox__image {
  height: calc(100% - 135px);
  width: 100%;
  object-fit: contain;
  border-bottom: 1px solid #d2d2d2; }

.portfolio__navArea .NavBox.large {
  height: 80%;
  min-height: 375px; }
  @media (min-height: 768px) {
    .portfolio__navArea .NavBox.large {
      height: 85%; } }

.portfolio__navArea .NavBox .NavBox__image {
  height: calc(85% - 165px); }
  @media (max-height: 768px) {
    .portfolio__navArea .NavBox .NavBox__image {
      height: calc(85% - 165px); } }
  @media (min-height: 768px) and (max-height: 960px) {
    .portfolio__navArea .NavBox .NavBox__image {
      height: calc(85% - 120px); } }

.NavBox__itemTextArea {
  padding: 20px;
  color: #000;
  font-weight: normal;
  font-size: 14px;
  bottom: 0; }
  @media (max-height: 768px) {
    .NavBox__itemTextArea {
      padding: 15px;
      padding-top: 10px; } }

.NavBox__header {
  font-size: 20px;
  max-width: 350px;
  margin-bottom: 5px;
  font-weight: normal; }
  @media (max-height: 768px) {
    .NavBox__header {
      font-size: 16px; } }

.NavBox__subtitle {
  font-size: 16px;
  font-weight: normal; }

.NavBox__progressBar {
  width: 0%;
  height: 2px;
  position: absolute;
  background: #ED1D35;
  transition: width 500ms cubic-bezier(0.25, 0.8, 0.25, 1); }

.caseStudies {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 80px;
  padding-right: 160px; }
  .caseStudies.open {
    padding: 0; }

.caseStudies__navArea {
  position: absolute;
  left: 80px;
  right: 160px;
  margin-top: 143px;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-height: 800px) {
    .caseStudies__navArea {
      margin-top: 50px;
      height: 62%; } }

.stage {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: transparent;
  -webkit-animation: fadeIn 500ms linear;
          animation: fadeIn 500ms linear; }
  .stage.stage--portfolio .stage__header {
    display: none; }
  .stage.stage--portfolio .stage__image {
    width: 80%; }

.stage__header {
  z-index: 3;
  font-size: 14px;
  position: absolute; }
  .stage__header::before {
    height: 5px;
    width: 40px;
    background: #ED1D35;
    position: absolute;
    content: "";
    top: -10px; }

.stage__image {
  width: 100%;
  height: 100%; }

.stage__imageArea {
  bottom: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 80%; }

.imageArea__thumb {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 20px 20px; }
  .imageArea__thumb img {
    width: 200px;
    height: 100px; }
  .imageArea__thumb.isActive img {
    border: 2px solid #ED1D35; }

.fullOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.stage__back {
  position: absolute;
  top: 32px;
  left: 60px;
  text-transform: uppercase;
  font-weight: normal;
  color: #acacac;
  background: none;
  border: none;
  padding: 0;
  font-size: 14px;
  font-family: "Arial, Helvetica, sans-serif";
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 4; }

.stage__backIcon {
  width: 7px;
  margin-right: 5px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.stageTracker {
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  padding: calc(100px - 0.1%) 0; }
  @media (max-height: 768px) {
    .stageTracker {
      width: 80px;
      padding: calc(90px - 0.1%) 0; } }

.stageTracker__line {
  position: relative;
  width: 2px;
  margin-bottom: 5px;
  background: #acacac; }
  .stageTracker__line:last-of-type {
    margin-bottom: 0; }
  .stageTracker__line.hidden {
    display: none; }

.stageTracker__highlight {
  width: 2px;
  height: 0;
  margin-bottom: 5px;
  background: #ED1D35;
  transition: height 500ms linear; }
  .stageTracker__highlight.on {
    height: 100%; }

.stageTracker__arrowIcon {
  position: absolute;
  width: 50%; }
  .stageTracker__arrowIcon.red {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }

.stageTracker__arrow {
  position: absolute;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-weight: normal;
  color: #acacac;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }

.nextProduct__arrow,
.stageTracker__arrow {
  background-color: transparent; }
  .nextProduct__arrow.up,
  .stageTracker__arrow.up {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: 50px; }
    @media (max-height: 768px) {
      .nextProduct__arrow.up,
      .stageTracker__arrow.up {
        top: 30px; } }
  .nextProduct__arrow.down,
  .stageTracker__arrow.down {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    bottom: 28px; }
    @media (max-height: 768px) {
      .nextProduct__arrow.down,
      .stageTracker__arrow.down {
        bottom: 10px; } }
  .nextProduct__arrow.right,
  .stageTracker__arrow.right {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  .nextProduct__arrow.left,
  .stageTracker__arrow.left {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .nextProduct__arrow.hidden,
  .stageTracker__arrow.hidden {
    display: none; }
  .nextProduct__arrow img,
  .stageTracker__arrow img {
    border: 2px solid #ffffff;
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    cursor: pointer; }
  .nextProduct__arrow img.grey,
  .nextProduct__arrow img.red,
  .nextProduct__arrow img.white,
  .nextProduct__arrow img.disabled,
  .stageTracker__arrow img.grey,
  .stageTracker__arrow img.red,
  .stageTracker__arrow img.white,
  .stageTracker__arrow img.disabled {
    border-radius: 0px; }
  .nextProduct__arrow img.white,
  .stageTracker__arrow img.white {
    display: none; }
  .nextProduct__arrow img.disabled,
  .stageTracker__arrow img.disabled {
    background-color: #d2d2d2;
    border: 2px solid #d2d2d2;
    -webkit-filter: brightness(1);
            filter: brightness(1);
    cursor: default; }
  .nextProduct__arrow img.red,
  .stageTracker__arrow img.red {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    border: 2px solid #ED1D35;
    background-color: #ED1D35; }
  .nextProduct__arrow:hover img.disabled,
  .stageTracker__arrow:hover img.disabled {
    display: block; }
  .nextProduct__arrow:hover img.grey,
  .stageTracker__arrow:hover img.grey {
    display: none; }
  .nextProduct__arrow:hover img.white,
  .stageTracker__arrow:hover img.white {
    display: block;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }
  .nextProduct__arrow:hover img.red,
  .stageTracker__arrow:hover img.red {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); }
  .nextProduct__arrow:active img.white,
  .stageTracker__arrow:active img.white {
    -webkit-animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
            animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1); }
  .nextProduct__arrow:active img.grey,
  .stageTracker__arrow:active img.grey {
    -webkit-animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
            animation: greypulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1); }
  .nextProduct__arrow:active img.red,
  .stageTracker__arrow:active img.red {
    -webkit-animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
            animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1); }

@-webkit-keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1), 0 0 0 10px rgba(255, 0, 15, 0.15); } }

@keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1), 0 0 0 10px rgba(255, 0, 15, 0.15); } }

@-webkit-keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1), 0 0 0 10px rgba(172, 172, 172, 0.1); } }

@keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1), 0 0 0 10px rgba(172, 172, 172, 0.1); } }

.stageTracker__progress {
  display: flex;
  align-items: center;
  justify-content: center; }

.stageTracker__progressTextContainer {
  display: inline-block;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 100px;
  width: 500px;
  opacity: 0;
  transition: opacity 500ms linear; }
  .stageTracker__progressTextContainer.show {
    opacity: 1; }

.stageTracker__progressText {
  margin-bottom: 0;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0; }
  .stageTracker__progressText.black {
    color: #000;
    margin-left: 5px; }
  .stageTracker__progressText.grey {
    font-weight: normal;
    color: #acacac; }

.stageTracker.hideBars .stageTracker__arrow.up,
.stageTracker.hideBars .stageTracker__line {
  display: none; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  to {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@-webkit-keyframes rotateBack {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

@keyframes rotateBack {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

.techData {
  display: table; }
  .techData select {
    width: 100%;
    font-size: 14px;
    padding: 20px 30px;
    font-weight: normal;
    background-color: lightgray;
    border-radius: 0;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../../static/media/downred.0e04ec87.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position-x: calc(100% - 30px);
    background-position-y: 20px;
    outline: 0; }
    .techData select:active, .techData select:focus {
      background-color: lightgray; }
  .techData .techDataRow {
    padding: 0;
    display: table-row; }
    .techData .techDataRow .techData-text,
    .techData .techDataRow .techData-value {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      padding: 8px 30px;
      border-bottom: 1px solid #eee; }
    .techData .techDataRow .techData-text {
      font-size: 14px;
      padding-right: 20px; }
    .techData .techDataRow .techData-value {
      width: 30%;
      text-align: right; }

.moreInfo {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100px;
  font-size: 14px;
  font-weight: normal; }
  .moreInfo p {
    font-weight: normal; }
  .moreInfo .moreInfo-button {
    right: 40px;
    top: 35px;
    height: 4.7vh;
    font-size: 12px;
    font-weight: normal; }
    .moreInfo .moreInfo-button:hover {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); }
  .moreInfo .moreInfo-panel {
    display: none;
    padding: 90px 0;
    background-color: white;
    height: 100%;
    overflow-y: auto;
    border-left: 2px solid #eee; }
  .moreInfo .moreInfo-menu {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
    border-bottom: 2px solid #ED1D35; }
  .moreInfo .moreInfo-menuItem {
    display: inline-block;
    flex: 1 1;
    color: black;
    background-color: lightgray;
    text-overflow: ellipsis;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: normal;
    position: relative;
    font-size: 14px;
    padding: 0;
    text-align: center;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px; }
    @media (max-width: 1200px) {
      .moreInfo .moreInfo-menuItem {
        white-space: normal; } }
    .moreInfo .moreInfo-menuItem.active {
      color: white;
      background-color: #ED1D35;
      border-top: 1px solid #ED1D35;
      border-left: 1px solid #ED1D35;
      border-right: 1px solid #ED1D35; }
  .moreInfo .moreInfo__overview {
    padding: 0 30px; }
  .moreInfo .moreInfo__techDataHeader {
    padding: 15px 0px;
    border-bottom: 1px solid #eee; }
  .moreInfo .moreInfo__media {
    margin-top: 10px; }
    .moreInfo .moreInfo__media img {
      margin-right: 10px;
      height: 22px;
      width: 22px; }
    .moreInfo .moreInfo__media img.mediaIcon {
      float: left;
      margin-top: -3px; }
    .moreInfo .moreInfo__media img.arrow {
      float: right;
      height: 16px;
      width: 16px;
      color: red;
      margin: 0; }
  .moreInfo .moreInfo__mediaRow {
    padding: 15px 30px;
    border-bottom: 1px solid #eee; }
    .moreInfo .moreInfo__mediaRow:hover {
      cursor: pointer;
      font-weight: normal; }
    .moreInfo .moreInfo__mediaRow * {
      vertical-align: middle; }
  .moreInfo .closeIcon {
    display: none;
    height: 12px;
    width: 12px;
    position: relative;
    top: 10px;
    left: 10px; }
  .moreInfo.open {
    height: 100vh;
    width: 35vw; }
    .moreInfo.open .button__text {
      vertical-align: middle; }
    .moreInfo.open .closeIcon {
      display: block; }
    .moreInfo.open .moreInfo-panel {
      display: block; }

.group {
  position: absolute;
  top: 0;
  left: 80px;
  z-index: 3;
  height: 100vh;
  width: calc(100% - 80px);
  background: #fff;
  transition: opacity 500ms linear; }
  .group.hide {
    opacity: 0; }
  .group.noDisplay {
    display: none; }
  .group.noBackground {
    background: none;
    background-color: transparent; }

.group__navigation.entering {
  opacity: 1;
  -webkit-animation: fadeIn 500ms linear;
          animation: fadeIn 500ms linear; }

.group__navigation.exiting {
  opacity: 0; }

.group__button {
  position: absolute;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  padding: 10px;
  letter-spacing: 2px;
  color: #fff;
  background-color: #ED1D35;
  border: none;
  font-size: 12px;
  font-weight: normal;
  font-family: "Arial, Helvetica, sans-serif";
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  z-index: 10;
  left: calc(50% - (150px / 2));
  bottom: 25px; }

.group__scrollDown {
  position: absolute;
  bottom: 0;
  width: 150px;
  left: calc(50% - (150px / 2));
  bottom: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .group__scrollDown.animating .group__scrollDownArrow {
    -webkit-animation: arrowMove 2000ms linear;
            animation: arrowMove 2000ms linear; }

.group__scrollDownText {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 1px;
  color: #acacac;
  margin: 0; }

.group__scrollDownArrow {
  width: 15px;
  margin-bottom: 12px; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes arrowMove {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes arrowMove {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.portfolio {
  height: 100%;
  width: 100%;
  padding: 80px;
  padding-top: 80px;
  -webkit-animation: fadeIn 1000ms linear;
          animation: fadeIn 1000ms linear; }
  @media (max-height: 768px) {
    .portfolio {
      padding: 30px;
      padding-top: 60px;
      padding-left: 50px; } }
  .portfolio .header__subtitle {
    max-width: 700px; }
  .portfolio.hide {
    display: none; }

.portfolio__navArea {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 60px;
  height: calc(100% - (20px * 4) - 80px); }
  @media (min-height: 768px) {
    .portfolio__navArea {
      margin-top: 30px; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.splashScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-left: 80px;
  padding-top: 160px;
  background-color: white;
  z-index: 10; }
  .splashScreen .standard__lhs {
    width: 35%; }
  .splashScreen.slimContent .standard__lhs {
    width: 35%; }
  .splashScreen.hidden {
    display: none; }

.splashScreen__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover; }
  .splashScreen__video.contain {
    object-fit: contain; }

.splashScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover; }
  @media (max-height: 768px) {
    .splashScreen__image {
      object-fit: contain; } }
  @media (min-height: 768px) and (max-height: 960px) {
    .splashScreen__image {
      object-fit: contain; } }
  @media (max-height: 1200px) {
    .splashScreen__image {
      object-fit: contain; } }
  .splashScreen__image.contain {
    object-fit: contain; }
  .splashScreen__image.lighten {
    opacity: 0.3; }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.section {
  height: 100vh;
  padding: 0 0 0 80px;
  position: relative;
  z-index: 3;
  background: #fff;
  transition: opacity 1s linear;
  opacity: 1; }
  .section.noBackground {
    background: none;
    background-color: transparent; }
  .section.hide {
    transition: opacity 1s linear;
    display: none;
    opacity: 0; }

.section__title {
  position: absolute;
  z-index: 3;
  font-size: 44px; }
  .section__title::before {
    height: 5px;
    width: 40px;
    background: #ED1D35;
    position: absolute;
    content: "";
    top: -10px; }

.section--entering {
  z-index: 5; }
  .section--entering.down {
    -webkit-animation: moveinDown 500ms;
            animation: moveinDown 500ms; }
  .section--entering.up {
    -webkit-animation: moveinUp 500ms;
            animation: moveinUp 500ms; }
  .section--entering .panel,
  .section--entering .section__header {
    display: none; }

.section--entered {
  top: 0; }

.section--exiting {
  top: 0;
  position: absolute;
  width: 100%; }
  .section--exiting .panel,
  .section--exiting .section__header {
    display: none; }

.verticalNav {
  background: #fff;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  display: flex;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  width: 80px;
  box-shadow: 0.5px 0px 50px rgba(0, 0, 0, 0.07);
  -webkit-user-select: none;
          user-select: none; }
  .verticalNav.border {
    border-right: 1px solid #eee; }

.verticalNav__gridIntegration {
  white-space: nowrap;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  font-weight: normal;
  margin-left: 5px; }

.verticalNav__list {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }

.verticalNav__item {
  cursor: pointer;
  flex-wrap: wrap;
  position: relative;
  height: 110px;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  color: #000;
  font-size: 12px;
  text-decoration: none !important; }
  .verticalNav__item img {
    -webkit-filter: brightness(0);
            filter: brightness(0); }
  .verticalNav__item:hover {
    cursor: pointer; }
  .verticalNav__item.isActive, .verticalNav__item:hover {
    background: #ED1D35;
    color: #fff; }
    .verticalNav__item.isActive img, .verticalNav__item:hover img {
      -webkit-filter: brightness(1);
              filter: brightness(1); }
    .verticalNav__item.isActive .verticalNav__icon.white, .verticalNav__item:hover .verticalNav__icon.white {
      display: block; }
    .verticalNav__item.isActive .verticalNav__icon.black, .verticalNav__item:hover .verticalNav__icon.black {
      display: none; }
  .verticalNav__item:not(:last-of-type)::before {
    position: absolute;
    content: "";
    bottom: 0;
    /* left: 10%; */
    height: 2px;
    width: 100%;
    z-index: -1;
    background: #eee; }
  .verticalNav__item:last-of-type::before {
    position: absolute;
    content: "";
    bottom: 0;
    /* left: 10%; */
    height: 2px;
    width: 100%;
    z-index: -1;
    background: #eee; }
  .verticalNav__item:last-of-type::after {
    position: absolute;
    content: "";
    bottom: 0;
    /* left: 10%; */
    height: 2px;
    width: 100%;
    z-index: -1;
    background: #eee; }

.verticalNav__exit {
  position: absolute;
  bottom: 0; }

.verticalNav__link {
  margin-top: 5px;
  width: 80px;
  padding: 0 3px; }

.verticalNav__icon {
  height: 22.85714px;
  width: 80px; }
  .verticalNav__icon.white {
    display: none; }

.verticalNav__buttonArea {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 2px solid #eee; }

.loadScreen {
  position: absolute;
  z-index: 4;
  height: calc(100vh);
  width: 100vw;
  /*background-image: url("../../assets/images/background.jpg");*/
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loadScreen.alternative {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.7); }
    .loadScreen.alternative .loadScreen__icon {
      display: none; }
    .loadScreen.alternative .loadScreen__logo {
      display: none; }
  .loadScreen.grey {
    left: 80px;
    width: calc(100vw - 80px);
    background-image: none;
    background-color: #fff; }
    .loadScreen.grey .loadScreen__icon {
      display: none; }
    .loadScreen.grey .loadScreen__logo {
      display: none; }

.loadScreen__logo_wrapper {
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; }

.loadScreen__logo {
  width: 300px;
  max-height: 300px;
  margin-bottom: 40px; }
  .loadScreen__logo.loadScreen__logo_small {
    max-height: 50px; }

.loadScreen__logo_border {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 8px;
  margin: 15px 0px; }
  .loadScreen__logo_border .loadScreen__logo_border_first {
    width: 25%;
    height: 100%;
    background-color: lightgray; }
  .loadScreen__logo_border .loadScreen__logo_border_second {
    width: 25%;
    height: 100%;
    background-color: #ED1D35; }
  .loadScreen__logo_border .loadScreen__logo_border_third {
    width: 50%;
    height: 100%;
    background-color: gray; }

.loadScreen__mainArea {
  position: relative;
  text-align: center; }

.connectionWarning {
  position: absolute;
  left: 50%;
  width: 300px;
  font-size: 18px;
  padding: 22px 40px;
  color: white;
  font-family: "Arial, Helvetica, sans-serif";
  font-weight: normal;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: fadeIn 2s ease;
          animation: fadeIn 2s ease; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.loadScreen__spinner_wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; }
  .loadScreen__spinner_wrapper .loadScreen__spinner {
    height: 110px;
    width: 110px;
    border-bottom: 4px solid lightgray;
    border-left: 4px solid lightgray;
    border-right: 4px solid lightgray;
    border-top: 4px solid #ED1D35;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite; }
  .loadScreen__spinner_wrapper .loadScreen__icon {
    position: absolute;
    width: 40px;
    -webkit-filter: brightness(0);
            filter: brightness(0); }

.loadScreen__title {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 10px; }

.loadScreen__text {
  font-weight: normal;
  font-size: 14px; }

.loadScreen__performanceWarning {
  position: absolute;
  bottom: 80px;
  font-size: 14px;
  font-weight: normal;
  -webkit-animation: fadeIn 500ms linear;
          animation: fadeIn 500ms linear; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.modal {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 11; }

.modal__inner {
  background: #fff;
  height: 80%;
  width: 80%;
  position: relative;
  top: 10%;
  left: 10%;
  z-index: 4;
  padding: 40px;
  display: flex;
  justify-content: center; }
  .modal__inner.exit {
    height: 40%;
    width: 40%;
    left: 30%;
    top: 30%; }

.modal__back {
  text-transform: uppercase;
  font-weight: normal;
  color: #acacac;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: absolute;
  top: 36px;
  right: 60px;
  z-index: 10; }

.modal__icon {
  position: absolute;
  height: 50px;
  width: 50px;
  cursor: pointer;
  left: 0; }

.modal__lhs {
  width: 40%;
  height: 100%;
  padding: 10px;
  padding: 20px; }

.modal__text {
  font-weight: normal;
  font-size: 14px; }

.modal__rhs {
  width: 60%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column; }
  .modal__rhs.digital360 .modal__image {
    height: 100%; }

.modal__image {
  width: 100%;
  margin-top: 20px;
  top: 0;
  height: 40%;
  min-height: 280px;
  object-fit: cover; }
  @media (min-width: 1400px) {
    .modal__image {
      max-height: 400px; } }

.modal__columnArea {
  display: flex; }

.modal__column {
  width: calc(100% / 3);
  flex-grow: 1;
  border: 1px solid #eee;
  margin-top: 10px; }
  .modal__column:not(:last-of-type) {
    margin-right: 10px; }

.modal__columnHeader {
  background: #eee;
  height: 70px;
  padding: 5px; }

.modal__columnTitle {
  font-size: 16px;
  font-weight: normal;
  margin: 0; }

.modal__columnSubtitle {
  font-size: 14px;
  font-weight: normal;
  margin: 5px 0; }

.modal__columnList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: normal; }

.modal__columnItem {
  margin-bottom: 10px; }

.modal__list {
  margin: 0;
  padding-left: 20px;
  list-style-type: none;
  margin-top: 40px; }

.modal__listItem {
  font-weight: normal;
  margin-bottom: 5px;
  position: relative; }
  .modal__listItem::before {
    content: '-';
    left: -18px;
    position: absolute;
    top: 0; }

.modal__exit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.modal__exitIcon {
  height: 15%;
  width: 15%;
  margin-bottom: 5%; }

.modal__exitHeader {
  margin: 0;
  font-weight: normal;
  max-width: 80%;
  text-align: center;
  font-size: 32px; }

.modal__exitOptions {
  display: flex;
  width: 60%;
  justify-content: space-around;
  margin: 20px 0; }
  .modal__exitOptions button {
    margin: 5px; }

.modal__exitButton {
  text-transform: uppercase;
  font-weight: normal;
  color: #fff;
  background: #ED1D35;
  height: 40px;
  width: 120px;
  border-radius: 0px;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-right: 0; }
  .modal__exitButton.cancel {
    text-transform: uppercase;
    background-color: #eee;
    height: 40px;
    width: 110px;
    border-radius: 0px;
    padding: 12px 8px;
    letter-spacing: 1px;
    color: black;
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    outline: inherit;
    border: none; }

.modal__feedback {
  text-transform: uppercase;
  font-weight: normal;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 0; }

.mediaModal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-family: "Arial, Helvetica, sans-serif";
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 11; }

.mediaModal__inner {
  background: #fff;
  height: 100%;
  width: 100%;
  position: relative;
  top: 0%;
  left: 0%;
  z-index: 4;
  padding: 20px; }

.mediaModal__title {
  float: left; }

.mediaModal__content {
  clear: both;
  width: 100%;
  height: 92%; }

.mediaModal__back {
  position: absolute;
  top: 16px;
  right: 40px;
  z-index: 10;
  text-transform: uppercase;
  background-color: #eee;
  height: 40px;
  width: 110px;
  border-radius: 0px;
  padding: 12px 8px;
  letter-spacing: 1px;
  color: black;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
  outline: inherit;
  border: none; }

@-webkit-keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@keyframes moveinDown {
  from {
    top: 100vh; }
  to {
    top: 0; } }

@-webkit-keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

@keyframes moveinUp {
  from {
    top: -100vh; }
  to {
    top: 0; } }

.unityContent {
  position: absolute;
  top: 0;
  left: 80px;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none; }
  .unityContent.showing {
    display: block; }
    .unityContent.showing .unityContent__nav {
      opacity: 1; }
  .unityContent .header {
    position: absolute;
    top: 80px;
    left: 80px;
    z-index: 10; }
    @media (max-height: 768px) {
      .unityContent .header {
        left: 60px; } }
  .unityContent .stageTracker__arrow {
    position: absolute;
    bottom: 30px;
    right: 80px;
    padding: 0;
    margin: 0; }
    @media (max-height: 768px) {
      .unityContent .stageTracker__arrow {
        right: 100px; } }
    .unityContent .stageTracker__arrow .nextProduct__arrowIcon {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      transition: -webkit-transform 1s ease;
      transition: transform 1s ease;
      transition: transform 1s ease, -webkit-transform 1s ease;
      height: 40px;
      width: 40px; }

.unityContent__nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 79.5%;
  bottom: 20px;
  left: calc(10% + 40px);
  opacity: 0;
  transition: opacity 2000ms linear;
  transition-delay: 2000ms; }

.unityContent__progressBarContainer {
  height: 3px;
  margin-bottom: 3px;
  width: 80%;
  background: #acacac; }

.unityContent__progressBar {
  height: 3px;
  background: #ED1D35;
  transition: width 500ms linear; }

.unityContent__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; }
  .unityContent__button img.unityContent__icon {
    border-radius: 50px; }
  .unityContent__button:hover img.unityContent__icon {
    box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.3); }
  .unityContent__button:active img.unityContent__icon {
    -webkit-animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
            animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1); }

@-webkit-keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1), 0 0 0 10px rgba(255, 0, 15, 0.15); } }

@keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1), 0 0 0 10px rgba(255, 0, 15, 0.15); } }

@-webkit-keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1), 0 0 0 10px rgba(172, 172, 172, 0.1); } }

@keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1), 0 0 0 10px rgba(172, 172, 172, 0.1); } }

.unityContent__icon {
  height: 50px;
  width: 50px;
  cursor: pointer; }
  .unityContent__icon.alt {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .unityContent__icon.right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .unityContent__icon.right.alt {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg); }

.unity[style] {
  width: 100% !important;
  height: 100% !important; }

#__ruw[style] {
  background-color: white !important; }

canvas {
  width: 100%;
  height: 100%;
  display: block; }

