@import "../../assets/styles/settings/_index";

$icon-size: 32px;
$icon-pad-mod: 2.5;

.iconList {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 160px;
  margin-top: 150px;
  @media (min-height: 768px) {
    margin-top: 110px;
  }

  .iconList__column {
    flex: 1;
    text-align: center;

    hr {
      height: 2px;
      background-color: $brand-grey--light;
      border: 0;
      width: 40px;
    }

    .iconList__icon-container {
      width: $icon-size * $icon-pad-mod;
      height: $icon-size * $icon-pad-mod;
      margin-left: auto;
      margin-right: auto;

      .iconList__icon {
        width: 40px;
        margin-top: 6px;
        vertical-align: middle;
      }
      padding: $icon-size / $icon-pad-mod;

      margin-bottom: 5px;
    }

    font-size: 18px;
    font-weight: normal;
    .iconList__valueText {
      font-size: 12px;
    }
    .iconList__value,
    .iconList__header {
      font-weight: normal;
      padding: 10px 0;
      font-size: 22px;
    }
  }
}
