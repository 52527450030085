@import '../../assets/styles/settings/_index';

.caseStudies {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: $nav-width;
  padding-right: $nav-width * 2;
  &.open {
    padding: 0;
  }
}

.caseStudies__navArea {
  position: absolute;
  left: $nav-width;
  right: $nav-width * 2;
  @media(min-height: 800px) {
    margin-top: 50px;
    height: 62%;
  }
  margin-top: 143px;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}