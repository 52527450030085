@import "../../assets/styles/settings/_index";
@import "../../assets/styles/tools/_index";

.NavBox {
  position: relative;
  &.large {
    height: 100%;
    min-height: 405px;
  }
  &.small {
    height: 30vh;
  }
  width: 12vw;
  display: inline-block;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  color: #000;
  text-decoration: none !important;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow 0.5s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: $card-shadow;
    .NavBox__progressBar {
      width: 100%;
    }
  }
  &:nth-child(5n) {
    margin-right: 0;
  }
  &.caseStudy {
    height: 100% !important;
  }
  &.width {
    &:not(:last-of-type) {
      margin-right: $margin--large * 4;
      &.smallRightMargin {
        margin-right: $margin--large * 2;
      }
    }
  }
}

.NavBox__image {
  height: calc(100% - 135px);
  width: 100%;
  object-fit: contain;
  border-bottom: 1px solid #d2d2d2;
}

.portfolio__navArea {
  .NavBox {
    &.large {
      height: 80%;
      min-height: 375px;
      @media (min-height: 768px) {
        height: 85%;
      }
    }
    .NavBox__image {
      height: calc(85% - 165px);
      @media (max-height: 768px) {
        height: calc(85% - 165px)
      }
      @media (min-height: 768px) and (max-height: 960px) {
        height: calc(85% - 120px)
      }
    }
  }
}

.NavBox__itemTextArea {
  padding: $padding--large;
  color: $brand-color;
  font-weight: normal;
  font-size: $font--small;
  bottom: 0;
  @media (max-height: 768px){
    padding: 15px;
    padding-top: 10px;
  }
}

.NavBox__header {
  font-size: $font--medium;
  max-width: 350px;
  margin-bottom: $margin--small;
  font-weight: normal;
  @media (max-height: 768px) {
    font-size: 16px;
  }
}

.NavBox__subtitle {
  font-size: $font--smallHeader;
  font-weight: normal;
}

.NavBox__progressBar {
  width: 0%;
  height: 2px;
  position: absolute;
  background: $brand-color--alt;
  transition: width $timing--quick cubic-bezier(.25,.8,.25,1);
}
