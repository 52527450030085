@import '../../assets/styles/settings/_index';
@import '../../assets/styles/tools/_index';

.timeline {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  transition: left $timing--quick linear;
}

.timeline__column {
  height: 100%;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
}

.timeline__item {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: $padding--large * 3;
  padding-top: 30%;
  align-items: center;
  overflow: hidden;
  &.inversePadding {
    padding-top: calc(30% - (#{$padding--large} * 3));
    padding-bottom: $padding--large * 6;
  }
}

.timeline__year {
  font-size: 60px;
  font-weight: normal;
  margin-bottom: $margin;
  width: 100%;
  @media (min-height: 800px) {
    font-size: 70px;
  }
}

.timeline__text {
  font-size: $font--medium;
  font-weight: normal;
  margin-bottom: $margin;
  @media (min-height: 800px) {
    margin-bottom: $margin--large;
  }
  width: 100%;
}

.timeline__image {
  position: absolute;
  top: 38%;
  width: calc(100% - #{$padding--large * 6});
  object-fit: scale-down;
  @media(max-height: 800px) {
    top: 45%;
  }
}

.timeline__nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  bottom: $margin--large;
  left: calc(10% + #{$nav-width / 2});
  animation: fadeIn $timing--quick linear;
}

.timeline__progressBarContainer {
  height: 3px;
  margin-bottom: 3px; //eye correction
  width: 80%;
  background: $brand-grey;
}

.timeline__progressBar {
  height: 3px;
  background: $brand-color--alt;
  transition: width $timing--quick linear;
}

.timeline__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.timeline__icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
  &.right {
    transform: rotate(180deg);
  }
}

.timeline__video {
  object-fit: cover;
  width: 100%;
  z-index: -3;
  &.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &.small {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0 $padding--large * 3;
    object-fit: scale-down;
  }
}

.timeline__videoOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -1;
}

.timeline__imageOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -1;
}

.timeline__backgroundImageOverlay {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.8;
  z-index: -2;
}

.timeline__backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  object-fit: cover;
  &.animating {
    animation: kenBurns 40s linear;
    &.delay {
      animation-delay: 40s;
    }
  }
}

.timeline__back {
  position: fixed;
  right: 5px; //eye correction
  width: $nav-width;
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-grey;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
}

.timeline__backIcon {
  width: 60%;
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes kenBurns {
  0% {
    transform-origin: bottom left;
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.0);
  }
}