@import '../../assets/styles/settings/_index';

$button-width: 60px;
$wheel-width: 640px;
$content-width: 400px;

.wheel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wheel__video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wheel__circle {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: $brand-white;
  height: $wheel-width;
  width: $wheel-width;
  top: calc(50% - (#{$wheel-width} / 2) + #{$padding--large} * 4);
  left: $nav-width * 1;
  border-radius : 50%;
  overflow: hidden;
  @media(max-height: 850px) and (max-width: 1380px) {
    height: 500px;
    width: 500px;
    top: calc(50% - (#{$wheel-width} / 2) + #{$padding--large} * 6.5);
    left: $nav-width;
  }
}

.wheel__inner {
  position: absolute;
  height: 40%;
  width: 40%;
  background: $brand-white;
  left: 30%;
  top: 30%;
  display: flex;
  border-radius: 50%;
  align-items: center;
  border: 1px solid $brand-grey;
  @media(max-height: 850px) and (max-width: 1380px) {
    height: 35%;
    width: 35%;
    left: 32.5%;
    top: 32.5%;
  }
}

.wheel__innerText {
  text-align: center;
  font-size: $font--medium;
  margin-top: $margin--large;
  @media(max-height: 850px) and (max-width: 1380px) {
    font-size: $font--small;
  }
}

.wheel__segment {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $brand-grey--light;
  &.selected {
    background: $brand-white;
  }
  &.top {
    padding-top: 20px;
  }
  &.left {
    padding-left: 20px;
  }
  &.bottom {
    padding-bottom: 20px;
  }
  &.right {
    padding-right: 20px;
  }
}

.wheel__segmentIcon {
  height: 50px;
  width: 50px;
  @media(max-height: 850px) and (max-width: 1380px) {
    height: 35px;
    width: 35px;
  }
}

.wheel__segmentHeader {
  margin: $margin--small 0;
  font-size: $font--smallHeader;
  @media(max-height: 850px) and (max-width: 1380px) {
    font-size: $font--small;
  }
}

.wheel__arrows {
  position: absolute;
  height: 100%;
  width: 100%;
}

.wheel__button {
  z-index: 3;
  text-transform: uppercase;
  width: 100px;
  padding: $padding;
  letter-spacing: 1px;
  color: $brand-white;
  background-color: $brand-color--alt;
  border: none;
  font-size: $font--smallest;
  font-weight: normal;
  font-family: $font-sans;
  border-radius: 0px;
  cursor: pointer;
  outline: inherit;
  @media(min-height: 830px) {
    height: 40px;
    width: 120px;
    font-size: $font--small;
  }
  &.disabled {
    background: $brand-grey--light;
    color: #a9a9a9;
  }
  &:hover {
    box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
  }
}

.wheel__content {
  height: $content-width;
  width: $content-width;
  position: absolute;
  top: calc(50% - (#{$content-width} / 2) + #{$padding--large} * 4);
  left: calc((#{$nav-width} * 3) + #{$wheel-width});
  z-index: 2;
  color: $brand-white;
  @media(max-height: 850px) and (max-width: 1380px) {
    left: calc((#{$nav-width} * 2) + 500px);
  }

}

.wheel__contentTextArea {
  display: none;
  &.showing {
    display: block;
    animation: fadeIn $timing--quick ease-in;
  }
}

.wheel__contentIconContainer {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: $brand-grey--light;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
}

.wheel__contentIcon {
  width: 50px;
  height: 50px;
}

.wheel__contentTitle {
  font-size: $font--large;
  font-weight: normal;
  margin: 0;
  
}

.wheel__contentSubTitle {
  font-size: $font--large;
  font-weight: normal;
  margin: 0;
}

.wheel__contentNavigation {
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.wheel__contentProgress {
  font-size: 14px;
}

.wheel__contentNavigationButton {
  height: 40px;
  width: 40px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0 $margin;
  &.right {
    transform: rotate(180deg);
  }
}