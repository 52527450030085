@import '../../assets/styles/settings/_index';

.header {
  max-width: 450px;
  &.fullscreen {
    position: absolute;
    left: $nav-width;
    top: $padding--large * 4;
  }
  &.unity {
    position: absolute;
    left: $nav-width;
    top: $padding--large * 4;
  }
  &.white {
    .header__pretitle {
      color: $brand-white;
    }
    .header__title {
      color: $brand-white;
      &::before {
        color: $brand-white;
      }
    }
    .header__subtitle {
      color: $brand-white;
    }
  }
  &.wide {
    max-width: 1000px;
    .header__title {
      z-index: 1;
    }
  }
  &.absolute {
    position: absolute;
    z-index: 1;
    left: $nav-width;
    top: $padding--large * 4;
  }
}

.overdark {
  .header {
    color: white;
    &::before {
      color: white;
    }
  }
}

.header__pretitle {
  text-transform: uppercase;
  font-weight: normal;
  font-size: $font--medium;
  color: $brand-color--alt;
  margin-bottom: 0;
}

.header__title {
  font-weight: normal;
  font-size: $font--largest;
  margin-bottom: 0;
  position: relative;
  @media(max-height: 960px) {
    font-size: $font--large;
  }
}

.header__subtitle {
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 0;
  position: relative;
  @media(max-height: 960px) {
    font-size: $font--small;
  }
}
