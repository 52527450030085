@import '../../assets/styles/settings/_index';

//@import '../../assets/images/icons/back.svg';

.techData {
  display: table;

  select {
    width: 100%;
    font-size: $font--small;
    padding: 20px 30px;
    font-weight: normal;

    background-color: lightgray;
    border-radius: 0;
    border: 0;

    -webkit-appearance: none;
    appearance: none;
    background-image: url(../../assets/images/icons/downred.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position-x: calc(100% - 30px);
    background-position-y: 20px;

    outline: 0;
    &:active,&:focus {
      background-color: lightgray;
    }
  }

  .techDataRow {
    padding: 0;

    display: table-row;

    .techData-text,
    .techData-value {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      padding: 8px 30px;
      border-bottom: 1px solid $brand-grey--light;
    }

    .techData-text {
      font-size: $font--small;
      padding-right: 20px;
    }
    .techData-value {
      width: 30%;
      text-align: right;
    }
  }
}