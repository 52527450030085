@import '../../assets/styles/settings/_index';

.intro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // background-position: 50% 50%;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.intro__image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  animation: kenBurns 60s linear;
  animation-delay: 2s;
}

#section2-group3-stage0 .intro__image {
  animation-play-state: paused;
}

.intro__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.intro__title {
  color: $brand-white;
  font-size: $font--largest;
  font-weight: normal;
  z-index: 2;
  margin-bottom: 0;
}

.intro__subtitle {
  font-weight: normal;
  color: $brand-white;
  font-size: $font--large;
  z-index: 2;
  margin-bottom: $margin--large;
}

.intro__text {
  line-height: 1.5;
  color: $brand-white;
  font-weight: normal;
  font-size: $font--small;
  max-width: 500px;
  text-align: center;
  z-index: 2
}

@keyframes kenBurns {
  0% {
    transform-origin: bottom left;
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.0);
  }
}