@import "../../assets/styles/settings/_index";
@import "../../assets/styles/tools/_index";

.unityContent {
  position: absolute;
  top: 0;
  left: $nav-width;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  &.showing {
    display: block;
    .unityContent__nav {
      opacity: 1;
    }
  }

  .header {
    position: absolute;
    top: $nav-width;
    left: $nav-width;
    z-index: 10;
    @media (max-height: 768px) {
      left: 60px;
    }
  }

  .stageTracker__arrow {
    position: absolute;
    bottom: 30px;
    right: 80px;
    padding: 0;
    margin: 0;
    @media (max-height: 768px) {
      right: 100px;
    }

    .nextProduct__arrowIcon {
      transform: rotate(270deg);
      transition: transform 1s ease;
      height: 40px;
      width: 40px;
    }
  }
}

.unityContent__nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 79.5%;
  bottom: $margin--large;
  left: calc(10% + #{$nav-width / 2});
  opacity: 0;
  transition: opacity $timing--long linear;
  transition-delay: $timing--long;
}

.unityContent__progressBarContainer {
  height: 3px;
  margin-bottom: 3px; //eye correction
  width: 80%;
  background: $brand-grey;
}

.unityContent__progressBar {
  height: 3px;
  background: $brand-color--alt;
  transition: width $timing--quick linear;
}

.unityContent__button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  img.unityContent__icon {
    border-radius: 50px;
  }
  &:hover {
    img.unityContent__icon {
      box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.3);
    }
  }
  &:active {
    img.unityContent__icon {
      animation: redpulse 0.75s 1 cubic-bezier(0.66, 0, 0, 1);
    }
  }
}

@keyframes redpulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 0, 15, 0.1),
      0 0 0 10px rgba(255, 0, 15, 0.15);
  }
}
@keyframes greypulse {
  to {
    box-shadow: 0 0 0 18px rgba(172, 172, 172, 0.1),
      0 0 0 10px rgba(172, 172, 172, 0.1);
  }
}

.unityContent__icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
  &.alt {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(180deg);
    &.alt {
      transform: rotate(270deg);
    }
  }
}

// Common styles for Unity components
.unity[style] {
  width: 100% !important;
  height: 100% !important;
}

#__ruw[style] {
  background-color: white !important;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}