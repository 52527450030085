@charset "UTF-8";

@keyframes moveinDown {
  from {
    top: 100vh; 
  }
  to {
    top: 0; 
  } 
}

@keyframes moveinUp {
  from {
    top: -100vh; 
  }
  to {
    top: 0; 
  } 
}

body {
  margin: 0; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0; 
}

figure {
  margin: 0; 
}

@font-face {
  font-family: "ABBvoice";
  src: url("./assets/fonts/ABBVoice/ABBvoice_Bd.ttf?") format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url("./assets/fonts/ABBVoice/ABBvoice_Md.ttf?") format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url("./assets/fonts/ABBVoice/ABBvoice_Rg.ttf?") format("truetype");
  font-weight: normal; 
}

@font-face {
  font-family: "ABBvoice";
  src: url("./assets/fonts/ABBVoice/ABBvoice_Lt.ttf?") format("truetype");
  font-weight: normal; 
}

* {
  box-sizing: border-box;
  user-select: none; 
}

body {
  background: #fff;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  user-select: none; 
}

a {
  color: #fff;
  text-decoration: none; 
}
a:hover, a:focus {
  outline: none;
  text-decoration: underline; 
}

.link--no-style:hover, .link--no-style:focus {
  text-decoration: none; 
}

.visuallyHidden {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px; 
}
.visuallyHidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix::before, .clearfix::after {
  content: '';
  display: table; 
}

.clearfix::after {
  clear: both; 
}
