@import "../../assets/styles/settings/_index";

.splashScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  padding-left: $nav-width;
  padding-top: $nav-width * 2;
  background-color: white;
  z-index: 10;

  .standard__lhs {
    width: 35%;
  }
  &.slimContent {
    .standard__lhs {
      width: 35%;
    }
  }
  &.hidden {
    display: none;
  }
}

.splashScreen__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  &.contain {
    object-fit: contain;
  }
}

.splashScreen__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  @media (max-height: 768px) {
    object-fit: contain;
  }
  @media (min-height: 768px) and (max-height: 960px)  {
    object-fit: contain;
  }
  @media (max-height: 1200px) {
    object-fit: contain;
  }
  &.contain {
    object-fit: contain;
  }
  &.lighten {
    opacity: 0.3;
  }
}
