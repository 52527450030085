@import '../../assets/styles/settings/_index';

.flexible {
  position: relative;
  height: 100%;
  width: calc(100% - #{$nav-width});
  padding-top: $padding--large * 4;
  padding-right: $nav-width;
  padding-left: $nav-width;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  // &::before {
  //   position: absolute;
  //   content: '';
  //   left: calc(25% + #{$padding--large} * 2);
  //   top: $padding--large * 9;
  //   height: 67%;
  //   width: 2px;
  //   background: $brand-grey--light;
  //   @media(max-height: 850px) {
  //     height: 410px;
  //     top: $padding--large * 7;
  //   }
  // }
}

.flexible__top {
  position: relative;
  margin-top: $margin--large * 2;
  margin-bottom: $margin--large * 2;
  @media(max-height: 850px) {
    margin-top: $margin--small;
    margin-bottom: $margin--small;
  }
  width: 100%;
  display: flex;
  height: 55%;
  // min-height: 400px;
}

.flexible__box {
  width: calc((100% - (4 * #{$padding--large})) / 4);
  &:not(:last-of-type) {
    margin-right: $margin--large * 2;
    @media(max-height: 850px) {
      margin-right: $margin--large
    }
  }
  &:first-of-type {
    margin-right: $margin--large * 4;
    @media(max-height: 850px) {
      margin-right: $margin--large * 2;
    }
    .flexible__boxImage {
      padding: 20px;
      @media(min-height: 910px) and (min-width: 1700px) {
        padding: 50px;
      }
    }
  }
  border: 2px solid $brand-grey--light;
}

.flexible__boxImage {
  @media(max-height: 800px) {
    height: 100px;
  }
  @media(min-height: 910px) and (min-width: 1700px) {
    height: 260px;
  }
  height: 150px;
  width: 100%;
  border-bottom: 2px solid $brand-grey--light;
  object-fit: contain;
}

.flexible__boxTextArea {
  padding: $padding--large;
}

.flexible__boxHeader {
  font-size: $font--medium;
  font-weight: normal;
  @media(max-height: 900px) {
    font-size: $font--smallHeader;
  }
}

.flexible__boxList {
  list-style: none;
  padding-left: $padding--large;
  margin: 0;
}

.flexible__boxItem {
  position: relative;
  margin-bottom: $margin--small;
  font-weight: normal;
  font-size: $font--small;
  &::before {
    position: absolute;
    content: '-';
    left: -12px;
    
  }
}

.flexible__bottom {
  display: flex;
}

.flexible__bottomLhs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(((100% - (4 * #{$padding--large})) / 4) + #{$padding--large * 4});
  @media(max-height: 850px) {
    width: calc(((100% - (4 * #{$padding--large})) / 4) + #{$padding--large * 2.5});
  }
}

.flexible__bottomRhs {
  width: 75%;
}

.flexible__lhsText {
  font-weight: normal;
  font-size: $font--smallHeader;
}

.flexible__icons {
  border: 1px solid $brand-black;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexible__item {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.flexible__itemImage {
  height: 40px;
  width: auto;
  margin-bottom: $margin--small;
  @media(max-height: 850px) {
    height: 25px;
  }
}

.flexible__itemTextArea {
  height: 40px;
}

.flexible__itemLabel {
  font-size: $font--small;
  max-width: 100px;
  font-weight: normal;
  margin: 0;
  text-align: center;
}

.flexible__itemSubLabel {
  margin: 0;
  font-size: $font--small;
  font-weight: normal;
  text-align: center;
}

.flexible__smallPrint {
  font-size: $font--small;
  margin: $margin 0;
  @media(max-height: 850px) {
    margin: 0;
  }
}