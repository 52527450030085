// reusable section transition animations

@keyframes moveinDown {
	from {
		top: 100vh;
	}

	to {
		top: 0;
	}
}

@keyframes moveinUp {
	from {
		top: -100vh;
	}

	to {
		top: 0;
	}
}
