@import '../../assets/styles/settings/_index';

.moreInfo {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  height: 100px;
  font-size: $font--small;
  font-weight: normal;
  p {
    font-weight: normal;
  }

  .moreInfo-button {
    right: 40px;
    top: 35px;
    height: 4.7vh;
    font-size: 12px;
    font-weight: normal;
    &:hover{
			box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    }
  }

  .moreInfo-panel {
    display: none;
    padding: 90px 0;
    background-color: white;
    height: 100%;

    overflow-y: auto;
    border-left: 2px solid $brand-grey--light;
  }

  .moreInfo-menu {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
    border-bottom: 2px solid $brand-color--alt;
  }
  .moreInfo-menuItem {
    display: inline-block;
    flex: 1;

    color: black;
    background-color: lightgray;

    text-overflow: ellipsis;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: normal;
    position: relative;

    font-size: $font--small;
    padding: 0;
    text-align: center;

    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;

    white-space: nowrap;    
    @media (max-width: 1200px) {
      white-space: normal;
    }

    &.active {
      color: white;
      background-color: $brand-color--alt;
      border-top: 1px solid $brand-color--alt;
      border-left: 1px solid $brand-color--alt;
      border-right: 1px solid $brand-color--alt;
    }

    padding-top: 10px;
    padding-bottom: 10px;
  }

  .moreInfo__overview
   {    
    padding: 0 30px;
  }

  .moreInfo__techDataHeader {
    padding: 15px 0px;
    border-bottom: 1px solid $brand-grey--light;
  }

  .moreInfo__media {
    margin-top: 10px;
    img {
      margin-right: 10px;
      height: 22px;
      width: 22px;
    }
    img.mediaIcon {
      float: left;
      margin-top: -3px;
    }
    img.arrow {
      float: right;
      height: 16px;
      width: 16px;
      color: red;
      margin: 0;
    }
  }
  .moreInfo__mediaRow {
    padding: 15px 30px;
    border-bottom: 1px solid $brand-grey--light;
    &:hover {
      cursor: pointer;
      font-weight: normal;
    }
    * {
      vertical-align: middle;
    }
    .mediaTitle {
    }
  }

  .closeIcon {
    display: none;
    height: 12px;
    width: 12px;

    position: relative;
    top: 10px;
    left: 10px;
  }

  &.open {
    height: 100vh;
    width: 35vw;
    //letter-spacing: 2px;
    
    .button__text {
      vertical-align: middle;
    }
    .closeIcon {
      display: block;
    }
    .moreInfo-panel {
      display: block;
    }
  }
}