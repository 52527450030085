@import "../../assets/styles/settings/_index";

.button {
  position: absolute;
  right: $nav-width * 1;
  top: $padding--large * 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 40px;
  width: 110px;
  border-radius: 0px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--smallest;
  cursor: pointer;
  outline: inherit;
  &.operate-button {
    right: 245px;
    background-color: $brand-grey--light;
    color: black;
    top: 35px;
    height: 4.7vh;
    font-size: 12px;
    font-weight: normal;
    &:hover {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
  &.alt {
    background: $brand-grey--light;
    color: #a9a9a9;
  }
  &.back {
    left: $nav-width;
    z-index: 10;
    text-align: center;
    top: 35px;
    font-size: $font--small;
    color: $brand-grey;
    border: 2px solid $brand-grey;
    border-radius: 0px;
    padding: 1px 20px 0 20px;
    @media (max-height: 768px) {
      left: 60px;
    }

    &:hover {
      background: $brand-grey;
      color: #fff;
    }
  }
  &.replay {
    background: $brand-grey--light;
    color: #a9a9a9;
    right: 280px;
    border: 1px solid #dbdbdb;
  }
  &.replay:hover {
    box-shadow: 0px 2px 8px rgba(200, 200, 200, 0.3);
  }
  &.close {
    right: $padding--large * 2;
    background: $brand-grey--light;
    color: #a9a9a9;
    &.timeline {
      top: 0;
    }
  }
  &.unityClose {
    right: $nav-width * 2;
    background: $brand-grey--light;
    color: #a9a9a9;
    border: 1px solid #dbdbdb;
  }
  &.unityClose:hover {
    box-shadow: 0px 2px 8px rgba(200, 200, 200, 0.3);
  }

  &.onboarding {
    right: 25px;
    background: $brand-color--alt;
    color: $brand-white;
    z-index: 2;
    top: 30px;
  }
  &.hidden {
    display: none;
  }
  &.switch {
    top: 0;
    left: 0;
    background: $brand-grey--light;
    color: #a9a9a9;
    &.active {
      background: $brand-color--alt;
      z-index: 1;
      color: $brand-white;
    }
    &.conventional {
      width: 160px;
    }
    &.digital {
      width: 110px;
      left: 150px;
    }
  }
  &.mapNav {
    text-transform: initial;
    white-space: nowrap;
    width: auto;
    padding: 0 20px;
    &.active {
      background-color: $brand-color--alt;
      color: white;
    }
    &.inactive {
      background-color: $brand-grey--light;
      color: black;
    }
    span {
      font-weight: normal;
      strong {
        font-weight: normal;
      }
    }
  }
  &.moreInfo-button {
    background-color: $brand-grey--light;
    color: black;
    &.active {
      button__text::before {
        content: "x";
      }
    }
    &.inactive {
    }
  }
}

.button__icon {
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
