@import '../../assets/styles/settings/_index';

.app {
	background: red;
	overflow: hidden;
	user-select: none;
}

*:focus {
	outline: none;
}

.app__shell {
	background: $brand-grey--light;
	position: relative;
}
