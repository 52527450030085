@import "../../assets/styles/settings/_index";

.standard {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  padding-right: $nav-width * 2;
  z-index: 1;
  &.fullscreen {
    padding-right: 0;
    animation: fadeIn $timing linear;
  }
  &.blackout {
    background-color: #363636;
    header {
      color: white;
      .header__title::before {
        color: white;
      }
    }
  }
  &.fullHeight {
    .standard__lhs {
      padding-top: 30vh;
    }
  }
  &.noBackground {
    background: none;
  }
  &.fullWidth {
    flex-direction: column;
  }
  &.fullSizeImage {
    width: 100%;
    .standard__video {
      padding-right: $nav-width;
    }
  }
  &.addScroll {
    .panel {
      @media (max-height: 900px) {
        height: calc(100% - 275px);
        overflow-y: scroll;
        .panel__paraText {
          padding-right: 3px;
        }
      }
    }
  }
  &.reverse {
    flex-direction: row-reverse;
  }
  &.fadeDelay {
    .standard__lhs {
      //animation: fadeIn $timing linear;
      //transition: opacity $timing linear;
      &.invisible {
        opacity: 0;
      }
      &.visible {
        animation: fadeIn $timing linear;
        opacity: 1;
      }
    }
  }
}

.standard__lhs {
  position: relative;
  height: 100%;
  width: 40%;
  padding: $padding--large * 3;
  padding-top: $padding--large * 4;
  padding-left: $nav-width;
  @media (max-height: 768px) {
    width: 50%;
    padding-top: $padding--large * 3;
    padding-left: 60px;
  }

  @media (max-height: 1040px) {
    width: 60%;
  }
  &.right-side {
    left: 65%;
  }
  &.fullWidth {
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }
  &.wideLeft {
    min-width: 45%;
  }

  .standard__icon {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

#section7-group0-stage1 .standard__lhs {
  width: 40%;
}

.standard__rhs {
  position: relative;
  height: 100%;
  width: 60%;
  &.wideLeft {
    width: 60%;
    z-index: -1;

    .standard__video {
      padding: calc((#{$padding--large} * 4) + 7%) 0;
    }
  }
  &.fullWidth {
    width: 100%;
    height: auto;
    padding-left: $nav-width;
  }
}

.standard__video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: brightness(105%);
}

.standard__image {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: $padding--large * 5 0;
  @media (min-height: 768px) {
    object-fit: contain;
  }
  &.diagram {
    object-fit: none;
    height: 100%;
  }
  &.right-side {
    right: 80%;
  }
  &.contain {
    @media (max-height: 900px) {
      object-fit: contain;
    }
  }
  &.cover {
    object-fit: cover;
  }
  &.dark {
    filter: brightness(20%);
  }
  &.invisible {
    opacity: 0;
  }
  &.visible {
    animation: fadeIn $timing--quick linear;
    opacity: 1;
  }
}
.standard__image__overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
.standard__image .standard__altMediaArea {
  position: absolute;
  height: 70%;
  top: 30%;
  padding-left: 24px;
  padding-right: 20px;
  &.fullSizeImage {
    top: 20%;
    height: 80%;
  }
  &.fullSizeVideo {
    height: 100%;
    top: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    z-index: -1;
  }
  &.cover {
    .standard__video {
      object-fit: cover;
    }
  }
  &.contain {
    .standard__video {
      object-fit: contain;
    }
  }
}

.standard__altImage {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  &.ability {
    padding-bottom: $padding--large * 2;
  }
  &.svg {
    top: 10%;
    z-index: 3;
    object-fit: contain;
    padding-left: $nav-width;
    padding-right: $nav-width * 2;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
