@import '../../assets/styles/settings/_index';
@import '../../assets/styles/tools/_index';

.unityDisplay {
  position: relative;
  height: 100%;
  width: 100%;
  display: none;
  &.fullWidth {
    .unityDisplay__lhs {
      width: 0%;
    }
    .unityDisplay__rhs {
      width: 100%;
      transition: none;
      left: 0;
    }
  }
}

.unityDisplay__lhs {
  position: absolute;
  left: 0;
  top: 0;
  padding: $padding--large * 2;
  width: 30%;
}

.unityDisplay__rhs {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: $brand-white;
  z-index: 1;
  width: 70%;
  height: 100%;
  left: 30%;
  top: 0;  
  transition: all $timing--quick linear;
  &.open {
    width: 100%;
    left: 0;
  }
}

.unityDisplay__back {
  position: fixed;
  top: $margin--large * 1.5;
  right: 5px; //eye correction
  width: $nav-width;
  text-transform: uppercase;
  font-weight: normal;
  color: $brand-grey;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  font-size: $font--small;
  letter-spacing: 1px;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $margin--large;
  &.exiting {
    opacity: 0;
  }
  &.entering {
    animation: fadeIn $timing linear;
  }
}

.unityDisplay__backIcon {
  width: 60%;
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}