@import "../../assets/styles/settings/_index";

.home {
  height: 100%;
  width: 100%;
  text-align: left;
  position: absolute;
  z-index: 2;
}

.home__headerArea {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  transition: all $timing--quick linear;
  opacity: 0;
  &.show {
    opacity: 1;
    z-index: 3;
  }
}

.home__subtitle,
.home__preHeader {
  position: relative;
  z-index: 2;
  font-weight: normal;
  color: $brand-white;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: $font--small;
  letter-spacing: 2px;
}

.home__header {
  color: $brand-white;
  z-index: 2;
  margin-top: $margin--large;
  font-size: $font--largest;
  max-width: 700px;
  text-align: left;
  font-weight: normal;
  &.onboarding {
    margin: $margin--large * 2 0;
    position: relative;
  }
}

.home__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home__onboardingImage {
  position: absolute;
  left: 0;
  top: 13.5%;
  height: 75%;
  z-index: 2;
}

.home__onboarding {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.home__number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: $margin--large 0;
}

.home__onboardingNumberIcon {
  height: 40px;
  width: 40px;
  border: 2px solid white;
  color: white;
  background-color: transparent;
  line-height: 40px;
  text-align: center;
}

.home__onBoardingNav {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 330px;
  top: 110px;
  left: -10px;
}

.home__onBoardingNav__line {
  width: 50%;
  height: 2px;
  background: white;
}
.home__onBoardingNav__numberIcon {
  height: 40px;
  width: 40px;
  position: relative;
}

#onBoardingNav__1,
#onBoardingNav__2,
#onBoardingNav__3 {
  width: 100px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}

.home__onboardingText {
  font-weight: normal;
  font-size: $font--smallHeader;
  color: #d2d2d2;
  max-width: 300px;
  margin-top: $margin * 1.5;
  margin-bottom: 0;
}

.home__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  &.darker {
    background: rgba(0, 0, 0, 0.9);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
