@import "../../assets/styles/settings/_index";

$icon-size: 44px;

.calloutArea {
  font-weight: normal;
  &.calloutTitleSmall {
    .calloutArea__title {
      font-weight: normal;
      margin: $margin--large 0;
      font-size: $font--small;
    }
  }
}

.calloutArea__main {
  &.horizontal {
    border-top: 2px solid $brand-grey--light;
    display: flex;
    justify-content: start;
    padding-top: $padding--large;
    .calloutArea__callout {
      @media (min-width: 1600px) {
        padding-right: $padding--large * 3;
      }
    }
  }
  &.noPadding {
    .calloutArea__callout {
      padding-top: $padding;
      padding-bottom: $padding;
      padding-right: 0;
    }
    .calloutArea__boldText {
      font-size: $font--smallHeader;
      @media (min-height: 850px) {
        font-size: $font--medium;
      }
    }
  }
  max-width: 85%;
}

.calloutArea__main > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

.calloutArea__title {
  font-weight: normal;
  margin: $margin--large 0;
  font-size: $font--smallHeader;
}

.calloutArea__callout {
  display: flex;
  align-items: center;
  padding: 16px;
  max-width: 440px;
  min-height: 55px;
  background-repeat: no-repeat;
  padding-left: calc(#{$padding--large} + 44px);

  background-position: 0px 10px;
  background-size: 44px 44px;
  background-position: 0% calc(50% - 10px);
  overflow: visible;

  @media (max-height: 800px) {
    background-position: 0% 50%;
  }
  @media (max-height: 850px) and (max-width: 1370px) {
    padding-top: 0px;
    padding-bottom: 15px;
    // background-size: 33px 33px;
    background-position: 0px 5px;
  }
}

.calloutArea__icon {
  width: $icon-size;
  height: $icon-size;
  margin-right: $margin--large;
  margin-bottom: $margin--large;
  align-self: flex-start;
}

.calloutArea__boldText {
  font-weight: normal;
  //font-size: 24px;
  font-size: $font--smallHeader;
  margin-bottom: 0;
}

.calloutArea__text {
  font-size: $font--small;
  margin: 0;
}

.calloutArea__textArea {
  margin-bottom: $margin--large;
  @media (max-height: 800px) {
    margin-bottom: 0;
  }
}

.calloutArea__list {
  padding-left: $padding--large;
  list-style: none;
  margin-bottom: 0;
}

.calloutArea__listItem {
  position: relative;
  &::before {
    position: absolute;
    left: -$padding--large;
    top: 0;
    content: "-";
  }
}
