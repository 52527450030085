@import '../../assets/styles/settings/_index';

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.8);
  padding: 0;
  transition: opacity $timing--long linear;
  &.exiting {
    opacity: 0;
  }
  .overlay__button.video-overlay {
    position: absolute;
    top: 68%;
    left: 86.5%;
    width: 13%;
    line-height: 54px;
    animation: fadeIn $timing linear;
    //transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%);


    &.substations0 {
      top: 52.5%;
      right: 9%;
    }
    &.substations1 {
      top: 70%;
      right: 9%;
    }
    &:hover {
      box-shadow: 0px 2px 8px rgba(200,200,200,0.3);
    }
  }
}

.videoOverlay__body {
  color: $brand-white;
  max-width: 30%;
  font-size: $font--small;
  font-weight: normal;
  position: absolute;
  z-index: 4;
  top: 240px;
  left: 35%;
  text-align: center;
  &.noSubTitle {
    top: 187px;
  }
}

.videoOverlay__imageArea {
  position: relative;
  height: 100%;
  width: 100%;
}

.buttonContainer,
.videoOverlay__video {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: $margin--large;
  margin-top: 0;
  object-fit: cover !important;
  width: 100%;
  height: 100vh;
  &.fading {
    // opacity: 0;
    // animation: fadeOut 2s linear;
  }
}
.buttonSubContainer {
  width: 1840px;
  height: 1080px;
  object-fit: cover !important;
  background-color: rgba(255, 0, 0, 0.5);
  position: relative;
}

.videoOverlay__hidden {
  display: none;
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}


