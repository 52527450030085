@import '../../assets/styles/settings/_index';
@import '../../assets/styles/tools/_index';

.section {
	height: 100vh;
	padding: 0 0 0 $nav-width;
	position: relative;
	z-index: 3;
	background: $brand-white;
	&.noBackground {
		background: none;
		background-color: transparent;
		//display: none;
	}

	transition: opacity 1s linear;
	opacity: 1;
	&.hide {
		transition: opacity 1s linear;
		display: none;
		opacity: 0;
	}
}

.section__title {
	position: absolute;
	z-index: 3;
	font-size: $font--largest;
	&::before {
		height: 5px;
		width: 40px;
		background: $brand-color--alt;
		position: absolute;
		content: "";
		top: -$margin;
	}
}

.section--entering {
	z-index: 5;

	&.down {
		animation: moveinDown $timing--quick;
	}

	&.up {
		animation: moveinUp $timing--quick;
	}

	.panel,
	.section__header {
		display: none;
	}
}

.section--entered {
	top: 0;
}

.section--exiting {
	top: 0;
	position: absolute;
	width: 100%;

	.panel,
	.section__header {
		display: none;
	}
}

