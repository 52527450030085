@import '../../assets/styles/settings/_index';

.group {
  position: absolute;
	top: 0;
	left: $nav-width;
	z-index: 3;
	height: 100vh;
	width: calc(100% - #{$nav-width});
	background: $brand-white;
  transition: opacity $timing--quick linear;
  &.hide {
    opacity: 0;
  }
  &.noDisplay {
    display: none;
  }
  &.noBackground {
    background: none;
    background-color: transparent;
  }
}

.group__navigation {
  &.entering {
    opacity: 1;
    animation: fadeIn $timing--quick linear;
  }
  &.exiting {
    opacity: 0;
  }
}

.group__button {
  position: absolute;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  padding: $padding;
  letter-spacing: 2px;
  color: $brand-white;
  background-color: $brand-color--alt;
  border: none;
  font-size: $font--smallest;
  font-weight: normal;
  font-family: $font-sans;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  z-index: 10;
  left: calc(50% - (150px / 2));
  bottom: 25px;
}

.group__scrollDown {
  position: absolute;
  bottom: 0;
  width: 150px;
  left: calc(50% - (150px / 2));
  bottom: $margin--large;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.animating {
    .group__scrollDownArrow {
      animation: arrowMove 2000ms linear;
    }
  }
}

.group__scrollDownText {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 1px;
  color: $brand-grey;
  margin: 0;
}

.group__scrollDownArrow {
  width: 15px;
  margin-bottom: 12px;
}



@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes arrowMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
