@import '../../assets/styles/settings/_index';

.mediaModal {
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  font-family: $font-sans;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  z-index: 11;
}

.mediaModal__inner {
  background: $brand-white;
  height: 100%;
  width: 100%;
  position: relative;
  top: 0%;
  left: 0%;
  z-index: 4;
  padding: $padding--large * 1;
}
.mediaModal__title {
  float: left;
}

.mediaModal__content {
  clear: both;
  width: 100%;
  height: 92%;
}

.mediaModal__back {
  position: absolute;
  top: 16px;
  right: 40px;
  z-index: 10;


  text-transform: uppercase;
  background-color: #eee;
  height: 40px;
  width: 110px;
  border-radius: 0px;
  padding: 12px 8px;
  letter-spacing: 1px;
  color: black;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
  outline: inherit;
  border: none;
}
