@import '../../assets/styles/settings/_index';
@import '../../assets/styles/tools/_index';

.overlay {
  position: absolute;
  background: rgba(0,0,0,0.8);
  height: 100%;
  width: calc(100vw - #{$nav-width});
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity $timing--quick linear;
  opacity: 0;
  &.exiting {
    opacity: 0;
  }
  &.entering {
    opacity: 0;
  }
  &.entered {
    opacity: 1;
  }
  &.unity {
    width: 100%;
  }
}

.overlay__title {
  color: $brand-white;
  margin: $margin--large*2 0;
  max-width: 560px;
  text-align: center;
  font-size: $font--largest;
}

.overlay__iconArea {
  color: $brand-white;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: $font--small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 
.overlay__icon {
  width: 50px;
  height: auto;
  margin-bottom: $margin--large;
}

.overlay__button {
  height: 50px;
  width: 150px;
  border-radius: 0px;
  background: $brand-color--alt;
  color: $brand-white;
  text-transform: uppercase;
  font-family: inherit;
  font-size: $font--small;
  font-weight: normal;
  letter-spacing: 2px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  &.sequence {
    position: absolute;
    top: 57%;
    right: 0.6%;
    width: 12%;
  }
}